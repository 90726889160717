<template>
  <div> 
    <h2>UPS configuratie</h2>
    
    <UpsConfig :upsen="upsen" :show-hardware-id="true" :show-delete-button="true" v-on:upsenListUpdated="onListUpdated"/>

    <v-form ref="form" class="mt-6">
        <v-row>
          <v-col cols="12" md="2">
            <v-text-field
              id="upsId"
              v-model="upsId"
              required filled
              disabled
            ></v-text-field>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>De UPS configuratie kan niet aangepast worden. De UPS kan alleen verwijderd of toegevoegd worden.</span>
            </v-tooltip>
          </span>
        </v-row>
        <v-row >
          <v-btn id="upsAddBtn" :disabled="!valid" class="mt-5 ml-3" depressed color="primary" @click="addUps">Toevoegen</v-btn>
        </v-row>
        <v-row class="mx-0 mt-6" >
          <span id="upsTooManyItemsWarning" v-if="tooManyItems" class="warning--text">Maximum number of UPS's reached</span>
        </v-row>
    </v-form>
  </div>
</template>

<script>
/**
 *  The UpsConfigForm component consists of a form with fields to configure an UPS.
 *  component: UpsConfig - summary table of ups's in the current iwks configuration
 */
import UpsConfig from '@/components/UpsConfig.vue'

export default {
    name: 'UpsConfigForm',

    data: () => ({
        valid: false,
        upsId: 'UPS',
        upsen: [{
          upsId: 'UPS',
          peername: '',
          community: ''
        }],
        tooManyItems: true
    }),

    methods: {
      /**
       *  Button click addUps
       *  Adds an UPS with the config of values filled out in the form to the array of ups's
       *  Resets the values of the form to the default values 
       */
      addUps() {
        if (!this.$refs.form.validate()) return;
        const ups = {
          upsId: this.upsId,
          peername: '',
          community: ''
        };
        this.upsen.push(ups);
      },

      /**
       *  The list of upsen is modified from within this component (when adding upsen to the list) and from the UpsConfig component (when deleting upsen from the list).
       *  Therefore, checking the size of the current number of ups's in the list should be done in this method , which is a vue event triggered from its child component UpsConfig.
       *  vue-event: upsenListUpdated -  when the list of ups's is updated in the UpsConfig the new updated list is sent to the Config vue.
       */
      onListUpdated() {        
        this.$emit('upsenListUpdated', this.upsen);
        if (this.upsen.length >= 1) {
          this.valid = false;
          this.tooManyItems = true;
        } else {
          this.valid = true;
          this.tooManyItems = false;
        }
      },
    },
    components: {
      UpsConfig
    }
  }
</script>