var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("LD16 configuratie")]),_c('Ld16Config',{attrs:{"ld16s":_vm.ld16s,"show-hardware-id":true,"show-delete-button":true},on:{"ld16sListUpdated":_vm.onListUpdated}}),_c('v-form',{ref:"form",staticClass:"mt-6",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"id":"ld16Id","rules":[_vm.idRules],"counter":20,"label":"Id","required":"","filled":""},model:{value:(_vm.hardwareId),callback:function ($$v) {_vm.hardwareId=$$v},expression:"hardwareId"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("ID moet uniek zijn. Het ID wordt gebruikt om Ld16s te onderscheiden en te herkennen in het testrapport.")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"id":"ld16Host","rules":_vm.hostRules,"items":_vm.hostOptions,"label":"Ld16 host","required":"","filled":""},model:{value:(_vm.host),callback:function ($$v) {_vm.host=$$v},expression:"host"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Het IP adres van de LD16 is op te zoeken in de geleverde IP lijst."),_c('br'),_vm._v(".41 is hoofdbaanweg rechts"),_c('br'),_vm._v(".42 is verbindingsweg rechts"),_c('br'),_vm._v(".43 is hoofdbaanweg links"),_c('br'),_vm._v(".44 is verbindingsweg links")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"id":"ld16Port","filled":"","items":_vm.portOptions,"rules":_vm.portRules,"return-object":false,"label":"poort","required":""},model:{value:(_vm.port),callback:function ($$v) {_vm.port=$$v},expression:"port"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("De Ld16 TCP poort voor de communicatie met de Ld16. De poort staat default goed ingevuld.")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"id":"ld16IcpconId","filled":"","items":_vm.icpconIdOptions,"return-object":false,"label":"ICP CON ID","required":""},model:{value:(_vm.icpconId),callback:function ($$v) {_vm.icpconId=$$v},expression:"icpconId"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Het ID van de ICP CON waar de LD16 op aangesloten is. In vrijwel alle gevallen zal dit icpcon-1 zijn (dit is de ICP CON met ip 192.168.1.3).")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"id":"ld16IcpconRelaisNummer","rules":_vm.icpconRelaisNummerRules,"label":"ICP CON Relais Nummer","required":"","filled":""},model:{value:(_vm.icpconRelaisNummer),callback:function ($$v) {_vm.icpconRelaisNummer=$$v},expression:"icpconRelaisNummer"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Duidt aan welk relais van de ICP CON gebruikt wordt om de LD16 te herstarten. Dit is te vinden in het kastenpakket.")])])],1)],1),_c('v-row',[_c('v-btn',{staticClass:"mt-5 ml-3",attrs:{"id":"ld16AddBtn","disabled":!_vm.valid,"depressed":"","color":"primary"},on:{"click":_vm.addLd16}},[_vm._v("Toevoegen")])],1),_c('v-row',{staticClass:"mx-0 mt-6"},[(_vm.tooManyItems)?_c('span',{staticClass:"warning--text",attrs:{"id":"ld16TooManyItemsWarning"}},[_vm._v("Maximum number of LD16s reached")]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }