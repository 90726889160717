<template>
  <v-app>
    <div class="pa-10">
      <h1>TestSessie configuratie</h1>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="mx-0 mt-6">
          <v-col cols="12" md="2" class="px-0">
            <v-select
              filled
              v-model="macAddressSelected"
              id="macAddress"
              :items="activeIwksList"
              item-text="macAddress"
              :rules="macAddressRules"
              :return-object="false"
              label="Mac adres"
            >
            </v-select>
            <v-snackbar
              id="snackbar"
              v-model="snackbar"
            >
              {{ snackbartext }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="pink"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </v-col>
          <span class="mt-3 ml-2">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on"
                  >mdi-information-outline</v-icon
                >
              </template>
              <span
                >Kijk op de pc welk MAC-adres je nodig hebt en selecteer deze in de
                lijst.</span
              >
            </v-tooltip>
          </span>
          <v-col cols="12" md="8" class="mt-5">
            <span id="macAddressWarning" v-if="macAddressError" class="error--text"
              >Er is een fout opgetreden met het ophalen van het MAC-adres. Probeer
              opnieuw of contact support.</span
            >
          </v-col>
        </v-row>
        <v-divider class="my-10"></v-divider>
        <generalConfigForm
          v-on:generalConfigurationChanged="onGeneralConfigurationUpdated"
          ref="generalConfiguration"
        />
        <v-divider class="my-10"></v-divider>
        <LanSwitchConfigForm v-on:lanswitchesListUpdated="onLanswitchesListUpdated" />
        <v-divider class="my-10"></v-divider>
        <IcpConConfigForm v-on:icpconsListUpdated="onIcpconsListUpdated" />
        <v-divider class="my-10"></v-divider>
        <iRoseConfigForm v-on:iRosesListUpdated="onIRoseListUpdated" />
        <v-divider class="my-10"></v-divider>
        <Sld4ConfigForm v-on:sld4sListUpdated="onSld4ListUpdated" />
        <v-divider class="my-10"></v-divider>
        <Ld16ConfigForm v-on:ld16sListUpdated="onLd16ListUpdated" />
        <v-divider class="my-10"></v-divider>
        <FalconRadarConfigForm v-on:falconRadarListUpdated="onFalconRadarListUpdated" />
        <v-divider class="my-10"></v-divider>
        <KoperKoppelvlakConfigForm v-on:koperKoppelvlakListUpdated="onKoperKoppelvlakListUpdated" />
        <v-divider class="my-10"></v-divider>
        <ProfibusConfigForm v-on:profibusListUpdated="onProfibusListUpdated" />
        <v-divider class="my-10"></v-divider>
        <UpsConfigForm v-on:upsenListUpdated="onUpsenListUpdated" />
        <v-divider class="my-10"></v-divider>

        <v-row class="mx-0 mt-6">
          <span id="errorsInConfigWarning" v-if="!valid" class="error--text"
            >Er zitten errors in de configuratie</span
          >
        </v-row>
        <v-row class="mx-0 mt-4">
          <v-btn
            id="initTestSessionBtn"
            :disabled="alreadyClicked"
            unit-test-id="button"
            depressed
            color="primary"
            @click="initTestSession"
            >TestSessie aanmaken</v-btn
          >
        </v-row>
        <v-row class="mx-0 mt-6">
          <span id="ketenWarning" v-if="ketenError" class="error--text"
            >Er is een fout opgetreden. Probeer opnieuw of contact support.</span
          >
        </v-row>
      </v-form>
    </div>
  </v-app>
</template>

<script>
/**
 *  The configuration view where a new test session is configured.
 *  Mac adress selection
 *  component: GeneralConfigForm - consists of the form for general configuration options.
 *  component: XXXConfigForm - form to configure component XXX
 *  UPS configuration
 */
import gql from "graphql-tag";
import GeneralConfigForm from "@/components/GeneralConfigForm.vue";
import IcpConConfigForm from "@/components/IcpConConfigForm.vue";
import iRoseConfigForm from "@/components/iRoseConfigForm.vue";
import Sld4ConfigForm from "@/components/Sld4ConfigForm.vue";
import Ld16ConfigForm from "@/components/Ld16ConfigForm.vue";
import FalconRadarConfigForm from "@/components/FalconRadarConfigForm.vue";
import ProfibusConfigForm from "@/components/ProfibusConfigForm.vue";
import KoperKoppelvlakConfigForm from "@/components/KoperKoppelvlakConfigForm.vue";
import UpsConfigForm from "@/components/UpsConfigForm.vue";
import LanSwitchConfigForm from "@/components/LanSwitchConfigForm.vue"

const activeIwksListQuery = gql`
  query activeIwksListQuery {
          activeIwksList {
            macAddress
          }
        }
`;

export default {
  name: "config",

  components: {
    GeneralConfigForm,
    IcpConConfigForm,
    iRoseConfigForm,
    Sld4ConfigForm,
    Ld16ConfigForm,
    FalconRadarConfigForm,
    ProfibusConfigForm,
    KoperKoppelvlakConfigForm,
    UpsConfigForm,
    LanSwitchConfigForm
  },

  apollo: {
    /**
     *  Query to retrieve the available mac addresses for this logged in user.
     */
    activeIwksList: {
      query: activeIwksListQuery,
      loadingKey: 'loading',
      pollInterval: 100,
      error(error) {
        this.macAddressError = true;
        console.log("error", error.message);
      },
    }
  },

  watch: {
    // Wanneer de data van de query wordt bijgewerkt, zal deze watcher worden uitgevoerd
    activeIwksList: {
      handler(newData, oldData) {
        if (newData.length > 0 && !this.isEqualMacAddresses(newData, oldData)) {
          this.snackbar = true;
        }
      },
    },
  },

  data: () => ({
    /**
     *  activeIwksList: used to store apollo query result
     *  valid: boolean to store whether the form is valid (i.e. has no errors)
     *  alreadyClicked: boolean used to disable the TESTSESSIE AANMAKEN button while a test session is being created to avoid duplicates
     *  macAddressError: textfield used to notify user that an error occured.
     *  macAddressSelected: currently selected mac adres (from the list or typed by user)
     *  macAddressRules: rules used to determine whether the field is a valid mac adres
     *  alias: alias for test session (kast-naam)
     *  aliasRules: rules used to determine whether the field is a valid alias
     *  iRoses: array of iRoses currently added to the configuration
     *  sld4s: array of sld4s currently added to the configuration
     *  ld16s: array of ld16s currently added to the configuration
     *  falconRadars: array of falcon radars currently added to the configuration
     *  profibuses: array of profibuses currently added to the configuration
     *  koperKoppelvlakken: array of koperkoppelvlakken currently added to the configuration
     */
    activeIwksList: [],
    valid: false,
    alreadyClicked: false,
    ketenError: false,
    macAddressError: false,
    macAddressSelected: "",
    macAddressRules: [
      (v) => !!v || "Host is required",
      (v) =>
        /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/.test(v) ||
        "Host must be a valid mac address",
    ],
    icpcons: [],
    iRoses: [],
    sld4s: [],
    ld16s: [],
    falconRadars: [],
    profibuses: [],
    koperKoppelvlak: null,
    ups: {
          upsId: 'UPS',
          peername: '',
          community: ''
        },
    lanswitches: {
      nrlanswitches: 2,
    },
    tempVarShowUPSConfig: false,
    snackbar: false,
    snackbartext: `Lijst met MAC adressen is bijgewerkt`,
  }),

  methods: {
    onGeneralConfigurationUpdated(generalConfiguration) {
      this.generalConfiguration = generalConfiguration;
    },

    validateForms() {
      this.valid = !!(
        this.$refs.form.validate() & this.$refs.generalConfiguration.validate()
      ); // Non short-circuiting
      return this.valid;
    },

    /**
     *  vue-event: icpconsListUpdated - when an ICP CON is added in the IcpConConfigForm it is added to the array here so that it is added to the config that is sent in the Apollo mutation.
     */
    onIcpconsListUpdated(icpcons) {
      this.icpcons = icpcons;
      console.log("icpcons updated " + JSON.stringify(this.icpcons));
    },
    /**
     *  vue-event: iRosesListUpdated - when an iRose is added in the iRoseConfigForm it is added to the array here so that it is added to the config that is sent in the Apollo mutation.
     */
    onIRoseListUpdated(iRoses) {
      console.log("iRoses updated" + JSON.stringify(iRoses));
      this.iRoses = iRoses;
    },
    /**
     *  vue-event: sld4sListUpdated - when a sld4 is added in the Sld4ConfigForm it is added to the array here so that it is added to the config that is sent in the Apollo mutation.
     */
    onSld4ListUpdated(sld4s) {
      console.log("Sld4s updated");
      this.sld4s = sld4s;
    },
    /**
     *  vue-event: ld16sListUpdated - when a ld16 is added in the Ld16ConfigForm it is added to the array here so that it is added to the config that is sent in the Apollo mutation.
     */
    onLd16ListUpdated(ld16s) {
      this.ld16s = ld16s;
      console.log("Ld16s updated: " + JSON.stringify(this.ld16s));
    },
    /**
     *  vue-event: falconRadarsListUpdated - when a falcon radar is added in the FalconRadarConfigForm it is added to the array here so that it is added to the config that is sent in the Apollo mutation.
     */
    onFalconRadarListUpdated(falconRadars) {
      console.log("Falcon radars updated" + JSON.stringify(falconRadars));
      this.falconRadars = falconRadars;
    },
    /**
     *  vue-event: profibusesListUpdated - when a Profibus is added in the ProfibusConfigForm it is added to the array here so that it is added to the config that is sent in the Apollo mutation.
     */
    onProfibusListUpdated(profibuses) {
      for (var i = 0; i < profibuses.length; i++) {
        for (var j = 0; j < profibuses[i].mussen.length; j++) {
          delete profibuses[i].mussen[j].id;
          delete profibuses[i].mussen[j].checked;
        }
      }

      for (var k = 0; k < profibuses.length; k++) {
        for (var l = 0; l < profibuses[k].beeldstanden.length; l++) {
          delete profibuses[k].beeldstanden[l].image;
        }
      }

      this.profibuses = profibuses;
    },
    /**
     *  vue-event: koperKoppelvlakListUpdated - when a koperkoppelvlak is added in the KoperKoppelvlakConfigForm it is added to the array here so that it is added to the config that is sent in the Apollo mutation.
     *  koperKoppelvlakken is an array of maximum size 1, so take the first element because this.koperKoppelvlak should be a JSON object not an array.
     */
    onKoperKoppelvlakListUpdated(koperKoppelvlakken) {
      if (koperKoppelvlakken.length == 1) {
        this.koperKoppelvlak = koperKoppelvlakken[0];
      } else {
        this.koperKoppelvlak = null;
      }
      console.log("Koperkoppelvlakken updated " + JSON.stringify(this.koperKoppelvlak));
    },
    /**
     *  vue-event: upsenListUpdated - when an UPS is added in the UpsConfigForm it is added to the array here so that it is added to the config that is sent in the Apollo mutation.
     */
    onUpsenListUpdated(upsen) {
      if (upsen.length == 1) {
        this.ups = upsen[0];
      } else {
        this.ups = null;
      }
      console.log("UPS updated " + JSON.stringify(this.ups));
    },
    /**
     *  vue-event: lanswitchesListUpdated - when an LAN Switch is added in the LanSwitchConfigForm it is added to the array here so that it is added to the config that is sent in the Apollo mutation.
     */
    onLanswitchesListUpdated(lanswitches) {
      this.lanswitches.nrlanswitches = lanswitches.length;
      console.log("LAN Switches updated : " + JSON.stringify(this.lanswitches));
    },
    /**
     *  Button click initTestSession
     *  Send the configuration of the filled out form (Mac adres, iRoses, Sld4s, Profibuses, etc.) as an apollo mutation to the backend
     */
    initTestSession() {
      if (!this.validateForms()) {
        return;
      }
      this.preProcessUps();
      this.alreadyClicked = true;
      this.ketenError = false;
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation initializeTestSessionMutation(
              $macAddress: String!
              $generalConfiguration: GeneralConfigurationInput!
              $lanswitches: LanSwitchConfigurationInput
              $ups: UpsConfigurationInput
              $icpcons: [IcpConConfigurationInput!]!
              $iRose2s: [IRose2ConfigurationInput!]!
              $sld4s: [VoertuigdetectorConfigurationInput!]!
              $ld16s: [VoertuigdetectorConfigurationInput!]!
              $falconRadars: [FalconRadarConfigurationInput!]!
              $profibussen: [ProfibusConfigurationInput!]!
              $koperKoppelvlak: KoperKoppelvlakConfigurationInput
            ) {
              initializeTestSession(
                macAddress: $macAddress
                iwksConfiguration: {
                  general: $generalConfiguration
                  lanswitches: $lanswitches
                  ups: $ups
                  icpcons: $icpcons
                  iRose2s: $iRose2s
                  sld4s: $sld4s
                  ld16s: $ld16s
                  falconRadars: $falconRadars
                  profibussen: $profibussen
                  koperKoppelvlak: $koperKoppelvlak
                }
              ) {
                id
              }
            }
          `,
          // Parameters
          variables: {
            macAddress: this.macAddressSelected,
            generalConfiguration: this.generalConfiguration,
            lanswitches: this.lanswitches,
            ups: this.ups,
            icpcons: this.icpcons,
            iRose2s: this.iRoses,
            sld4s: this.sld4s,
            ld16s: this.ld16s,
            falconRadars: this.falconRadars,
            profibussen: this.profibuses,
            koperKoppelvlak: this.koperKoppelvlak,
          },
        })
        .then((data) => {
          console.log(
            "New testSession created with id: " + data.data.initializeTestSession.id
          );
          this.$store.commit("setTestSessionId", data.data.initializeTestSession.id);
          this.$router.push({ name: "AMT Testen" });
        })
        .catch((error) => {
          console.error("error: " + error);
          this.ketenError = true;
          setTimeout(() => {
            this.alreadyClicked = false;
          }, 1000);
        });
    },
    preProcessUps() {
      if (this.ups == null) return;
      delete this.ups.upsId;
    },
    isEqualMacAddresses(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      const sortedArr1 = arr1.map((item) => item.macAddress).sort();
      const sortedArr2 = arr2.map((item) => item.macAddress).sort();

      for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
          console.log(sortedArr1[i]);
          console.log(sortedArr2[i]);
          console.log("this is not equal");
          return false;
        }
      }

      return true;
    },
  },
};
</script>
