<template>
  <div>
    <v-data-table
    id="upsenTable"
    :headers="headers"
    :items="upsen"
    class="elevation-1"
    item-key="id"
    :items-per-page="-1"
    :hide-default-footer="true"
    >
    <template v-slot:[`item.upsId`]="{ item }">
        <td :id="createElementId('id', item)" class="text-start">
            {{item.upsId}}
        </td>
    </template>
    <template v-slot:[`item.delete`]="{ item }">
        <v-icon
        :id="createElementId('delete', item)"
        small
        @click="deleteItem(item)"
        >
        mdi-delete
        </v-icon>
        </template>
    </v-data-table>
  </div>
</template>

<script>
   /**
    *  The UpsConfig component is a table where each row contains the configuration of an UPS
    */
  export default {
    name: 'UpsConfig',

    data: () => ({
       /**
        *  headers: columns displayed in the table
        */
        headers: [],
    }),

    /**
     *  vue-prop: upsen - the array of upsen where each UPS is a row in the table
     *  vue-prop: showHardwareId - boolean to determine whether the upsId should be shown as a column
     *  vue-prop: showDeleteButton - boolean to determine whether the delete button should be shown
     *  A list of ups config is used in the configuration page and testresultaten page.
     */
    props: {
        upsen: Array,
        showHardwareId: Boolean,
        showDeleteButton: Boolean
    },

    watch: {
        /**
         *  vue-event: upsenListUpdated - when the list of upsen is changed the new list is sent to parent (Config.vue)
         */
        upsen: function (updatedList) {
            this.$emit('upsenListUpdated', updatedList);
        }
    },

    /**
     *  The 'Id' column is only added to the table when the config shows a list of ups's.
     *  The delete icon is only added to the table when the config shows a list of ups's.
     */
    created () {
        if (this.showHardwareId) {
          this.headers.unshift({
            align: 'start',
            sortable: false,
            value: 'upsId',
          });
        }
        if (this.showDeleteButton) {
          this.headers.push({
            text: '',
            sortable: false,
            value: 'delete',
          });
        }
    },

    methods: {
        /**
         *  Button click deleteItem
         *  Deletes an ups from the array of ups's.
         *  This removes that ups config from the table.
         */
        deleteItem (item) {
            this.upsen.splice(this.upsen.indexOf(item), 1)
        },

        /**
         *  Method to create a unique HTML element id used for automatic testing
         */
        createElementId(element, item) {
            return element+"-"+item.upsId;
        }
    }
  }
</script>