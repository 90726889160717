<template>
  <div>
    <v-data-table
    id="iRosesTable"
    :headers="headers"
    :items="iRoses"
    class="elevation-1"
    item-key="id"
    :items-per-page="-1"
    :hide-default-footer="true"
    >
    <template v-slot:[`item.hardwareId`]="{ item }">
        <td :id="createElementId('id', item)" class="text-start">
            {{item.hardwareId}}
        </td>
    </template>
    <template v-slot:[`item.host`]="{ item }">
        <td :id="createElementId('host', item)" class="text-start">
            {{item.host}}
        </td>
    </template>
    <template v-slot:[`item.port`]="{ item }">
        <td :id="createElementId('port', item)" class="text-start">
            {{item.port}}
        </td>
    </template>
    <template v-slot:[`item.ssh.port`]="{ item }">
        <td :id="createElementId('ssh-port', item)" class="text-start">
            {{item.ssh.port}}
        </td>
    </template>
    <template v-slot:[`item.ssh.username`]="{ item }">
        <td :id="createElementId('ssh-username', item)" class="text-start">
            {{item.ssh.username}}
        </td>
    </template>
    <template v-slot:[`item.ssh.password`]="{ item }">
        <td :id="createElementId('ssh-password', item)" class="text-start">
            {{item.ssh.password}}
        </td>
    </template>
    <template v-slot:[`item.icpconId`]="{ item }">
        <td :id="createElementId('icpconId', item)" class="text-start">
            {{item.icpconId}}
        </td>
    </template>
    <template v-slot:[`item.icpconRelaisNummer`]="{ item }">
        <td :id="createElementId('icpconRelaisNummer', item)" class="text-start">
            {{item.icpconRelaisNummer}}
        </td>
    </template>
    <template v-slot:[`item.delete`]="{ item }">
        <v-icon
        :id="createElementId('delete', item)"
        small
        @click="deleteItem(item)"
        >
        mdi-delete
        </v-icon>
        </template>
    </v-data-table>
  </div>
</template>

<script>
   /**
    *  The iRoseConfig component is a table where each row contains the configuration of an iRose.
    */
  export default {
    name: 'iRoseConfig',

    data: () => ({
       /**
        *  headers: columns displayed in the table
        */
        headers: [
            {
                text: 'Host', 
                value: 'host', 
                sortable: false },
            { 
                text: 'Poort', 
                value: 'port',
                sortable: false,
            },
            { 
                text: 'Ssh Poort', 
                value: 'ssh.port',
                sortable: false,
            },
            { 
                text: 'Ssh Username', 
                value: 'ssh.username', 
                sortable: false,
            },
            { 
                text: 'Ssh Password', 
                value: 'ssh.password', 
                sortable: false,
            },
            {
                text: 'ICP CON ID',
                value: 'icpconId',
                sortable: false,
            },
            {
                text: 'ICP CON Relais Nummer',
                value: 'icpconRelaisNummer',
                sortable: false
            }          
        ],
    }),

    /**
     *  vue-prop: iRoses - the array of iRoses where each iRoses is a row in the table
     *  vue-prop: showHardwareId - boolean to determine whether the hardwareId should be shown as a column
     *  vue-prop: showDeleteButton - boolean to determine whether the delete button should be shown
     *  A single iRose config is used on the iRose page, above the tests of that specific iRose. 
     *  A list of iRoses config is used in the configuration page. 
     */
    props: {
        iRoses: Array,
        showHardwareId: Boolean,
        showDeleteButton: Boolean
    },

    watch: {
        /**
         *  vue-event: iRosesListUpdated - when the list of iRoses is changed the new list is sent to parent (Config.vue)
         */
        iRoses: function (updatedList) {
            this.$emit('iRosesListUpdated', updatedList);
        }
    },

    /**
     *  The 'Id' column is only added to the table when the config shows a list of iRoses.
     *  The delete icon is only added to the table when the config shows a list of iRoses.
     */
    created () {
        if (this.showHardwareId) {
          this.headers.unshift({
            text: 'Id',
            align: 'start',
            sortable: false,
            value: 'hardwareId',
          });
        }
        if (this.showDeleteButton) {
          this.headers.push({
            text: '',
            sortable: false,
            value: 'delete',
          });
        }
    },

    methods: {
        /**
         *  Button click deleteItem
         *  Deletes an iRose from the array of iRoses. 
         *  This removes that iRose config from the table.
         */
        deleteItem (item) {
            this.iRoses.splice(this.iRoses.indexOf(item), 1)
        },

        /**
         *  Method to create a unique HTML element id used for automatic testing
         */
        createElementId(element, item) {
            return element+"-"+item.hardwareId;
        }
    }
  }
</script>