<template>
  <div> 
    <h2>SLD4 configuratie</h2>
    
    <Sld4Config :sld4s="sld4s" :show-hardware-id="true" :show-delete-button="true" v-on:sld4sListUpdated="onListUpdated"/>

    <v-form ref="form" v-model="valid" class="mt-6">
        <v-row>
          <v-col cols="12" md="2">
            <v-text-field
              id="sld4Id"
              v-model="hardwareId"
              :rules="[idRules]"
              :counter="20"
              label="Id"
              required filled
            ></v-text-field>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>ID moet uniek zijn. Het ID wordt gebruikt om Sld4s te onderscheiden en te herkennen in het testrapport.</span>
            </v-tooltip>
          </span>
  
          <v-col cols="12" md="2">
            <v-select
              id="sld4Host"
              v-model="host"
              :rules="hostRules"
              :items="hostOptions"
              label="fl-comserver host"
              required filled
            ></v-select>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Het IP adres van de Sld4 is op te zoeken in de geleverde IP lijst.<br>.45 is fl-comserver 1 rechts<br>.46 is fl-comserver 2 rechts<br>.47 is fl-comserver 1 links<br>.48 is fl-comserver 2 links</span>
            </v-tooltip>
          </span>
  
          <v-col cols="12" md="2">
            <v-select
            id="sld4Port"
            filled
            v-model="port"
            :items="portOptions"
            :rules="portRules"
            :return-object="false"
            label="fl-comserver poort"
            required >
          </v-select>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>De Sld4 TCP poort voor de communicatie met de Sld4. De poort staat default goed ingevuld.</span>
            </v-tooltip>
          </span>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-text-field
              id="sld4Lusparen"
              v-model="lusparen"
              :rules="lusparenRules"
              label="Aantal Lusparen"
              required filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
                id="sld4IcpconId"
                filled
                v-model="icpconId"
                :items="icpconIdOptions"
                :return-object="false"
                label="ICP CON ID"
                required >
            </v-select>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Het ID van de ICP CON waar de SLD4 op aangesloten is. In vrijwel alle gevallen zal dit icpcon-1 zijn (dit is de ICP CON met ip 192.168.1.3).</span>
            </v-tooltip>
          </span>
          <v-col cols="12" md="2">
            <v-text-field
              id="sld4IcpconRelaisNummer"
              v-model="icpconRelaisNummer"
              :rules="icpconRelaisNummerRules"
              label="ICP CON Relais Nummer"
              required filled
            ></v-text-field>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Duidt aan welk relais van de ICP CON gebruikt wordt om de SLD4 te herstarten. Dit is te vinden in het kastenpakket.</span>
            </v-tooltip>
          </span>
        </v-row>

        <v-row >
          <v-btn id="sld4AddBtn" :disabled="!valid" class="mt-5 ml-3" depressed color="primary" @click="addSld4">Toevoegen</v-btn>
        </v-row>
        <v-row class="mx-0 mt-6" >
          <span id="sld4TooManyItemsWarning" v-if="tooManyItems" class="warning--text">Maximum number of Sld4s reached</span>
        </v-row>
    </v-form>        
  </div>
</template>

<script>
/**
  *  The Sld4ConfigForm component consists of a form with fields to configure an Sld4.
  *  component: Sld4Config - summary table of sld4s in the current iwks configuration
  */
import Sld4Config from '@/components/Sld4Config.vue'

  export default {
    name: 'Sld4ConfigForm',

    /**
     *  valid: boolean to store whether the form is valid (i.e. has no errors)
     *  hardwareId: string containing the sld4 ID
     *  host: string containing the host address
     *  hostOptions: the possible host options
     *  hostRules: rules used to determine whether the field is a valid host
     *  port: string containing the port number
     *  portOptions: array with possible default values for the fl-comserver port that the sld4-connector connects to
     *  portRules: rules used to determine whether the field is a valid port number
     *  lusparen: integer containing the number of lusparen
     *  lusparenRules: rules used to determine whether the field is a valid number of lusparen value
     *  sld4s: array of sld4s currently added to the configuration
     *  tooManyItems: boolean used to show an error message when the maximum number of sld4s in the list is reached
     */
    data: () => ({
        valid: true,
        hardwareId: 'fl-comserver-1',
        host: '192.168.1.45',
        hostOptions: ['192.168.1.45', '192.168.1.46', '192.168.1.47', '192.168.1.48'],
        hostRules: [
            v => !!v || 'Host is required',
            v => /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(v) || 'Host must be a valid ip address',
        ],
        port: '3001',
        portOptions: ['3001'],
        portRules: [
            v => !!v || 'Port is required',
            v => Number.isInteger(Number(v)) || "The value must be an integer number",
            v => Number(v) > 0 && Number(v) < 65536|| "The value must be a valid port number",
        ],
        lusparen: 1,
        lusparenRules: [
            v => !!v || 'Field is required',
            v => Number.isInteger(Number(v)) || "The value must be an integer number",
            v => Number(v) > 0 && Number(v) < 5|| "Valid values are 1-4",
        ],
        sld4s: [],
        tooManyItems: false,
        icpconId: 'icpcon-1',
        icpconIdOptions: ['icpcon-1', 'icpcon-2'],
        icpconRelaisNummer: 1,
        icpconRelaisNummerRules: [
            v => v!=="" || 'Field is required',
            v => Number.isInteger(Number(v)) || "The value must be an integer number",
            v => Number(v) >= 0 && Number(v) <= 7|| "Valid values are 0-7",
        ]
    }),

    methods: {
      /**
       *  Button click addSld4
       *  Adds an sld4 with the config of values filled out in the form tot he array of sld4s
       *  Resets the values of the form to the default values 
       */
      addSld4() {
        if (!this.$refs.form.validate()) return;
        const sld4 = {
          hardwareId: this.hardwareId,
          host: this.host,
          port: this.port,
          lusparen: this.lusparen,
          icpconId: this.icpconId,
          icpconRelaisNummer: this.icpconRelaisNummer,
        };
        this.sld4s.push(sld4);
        this.resetSld4InputFields();
      },

      /**
       *  Resets values of the form to default values
       */
      resetSld4InputFields() {
        this.hardwareId = this.newSld4Id();
        this.host = '192.168.1.45';
        this.port = '3001';
        this.lusparen = 1;
        this.icpconId = "icpcon-1"
        this.icpconRelaisNummer = 1;
      },
      /**
       *  Method used to generate a new unique id as default sld4 id in the config field
       */
      newSld4Id() {
        var nr = 0;
        var id = "fl-comserver-1";
        while (this.sld4s.some(el => el.hardwareId === id)) {
          nr++;
          id = 'fl-comserver-' + nr.toString();
        }
        return id;
      },

      /**
       *  The list of sld4s is modified from within this component (when adding sld4s to the list) and from the Sld4Config component (when deleting sld4s from the list).
       *  Therefore, checking the size of the current number of sld4s in the list should be done in this method , which is a vue event triggered from its child component Sld4Config.
       *  vue-event: sld4sListUpdated -  when the list of sld4s is updated in the Sld4Config the new updated list is sent to the Config vue.
       */
      onListUpdated() {        
        this.$emit('sld4sListUpdated', this.sld4s);
        if (this.sld4s.length >= 8) {
          this.valid = false;
          this.tooManyItems = true;
        } else {
          this.valid = true;
          this.tooManyItems = false;
        }
      },
      /**
       *  Method used to determine the rules for the id field in the form.
       *  Other rules are determined as arrays in data objects, but there you cannot access other data objects and we need to access the sld4s array
       */
      idRules( value ) {
        if (value.length == 0 || value === "" ) {
          return "Id is required";
        }

        if (value.length > 20 ) {
          return "The value must be less than 20 characters"
        }

        if (this.sld4s.some(el => el.hardwareId === value)) {
          return "Identifier must be unique";
        }

        return true;
      }
    },
    components: {
      Sld4Config
    }
  }
</script>