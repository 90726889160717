/**
 *  Component containing the list of previous test sessions of the logged in user.
 */
import {DateTimeFormatter, ZonedDateTime} from "@js-joda/core";
import "@js-joda/timezone";

//Order of tests in this map affects result of sortTest method, which is used in Testresultate.vue
let informationMap = {
    AMT_CONNECT: {
        title: "Connectie",
        name: "AMT Connectie",
        description: "Deze test checkt of er connectie gemaakt kan worden met de AMT module en of het wachtwoord goed is ingesteld.",
        instruction: ""
    },
    AMT_TOEGANG: {
        title: "Toegang",
        name: "AMT Toegang",
        description: "Deze test checkt of de setting 'User Consent' staat ingesteld op 'None' zodat toegang niet geblokkeerd is.",
        instruction: ""
    },
    AMT_BEHEER_NETWERK: {
        title: "Beheer netwerk",
        name: "AMT Beheer Netwerk",
        description: "Deze test checkt of de AMT instellingen overeenkomen met wat er bij het aanmaken van de testsessie is ingevuld. Dit moeten de waarden zijn die KPN heeft opgegeven.",
        instruction: ""
    },
    LAN_SWITCH_PING_1: {
        title: "Connectie test LAN switch 1",
        name: "LAN Switch 1 Connectie",
        description: "Deze test checkt of er communicatie is met de LAN switch 1.",
        instruction: "",
        hardwareId: "LANSwitch-1"
    },
    LAN_SWITCH_PING_2: {
        title: "Connectie test LAN switch 2",
        name: "LAN Switch 2 Connectie",
        description: "Deze test checkt of er communicatie is met de LAN switch 2.",
        instruction: "",
        hardwareId: "LANSwitch-2"
    },
    ICP_CON_CONNECT: {
        title: "Connectie",
        name: "ICP CON Connectie",
        description: "Deze test checkt of er communicatie is met de ICP CON en haalt metadata op.",
        instruction: ""
    },
    ICP_CON_DEFAULTS: {
        title: "Standaard waarden",
        name: "ICP CON Standaard waarden",
        description: "De 'power-on' en de 'safe' waarden dienen standaard ingeschakeld te zijn.",
        instruction: ""
    },
    IROSE2_CONNECT: {
        title: "Connectie",
        name: "iRose2 Connectie",
        description: "Deze test checkt of er een connectie is gemaakt met de iRose2.",
        instruction: ""
    },
    IROSE2_RECONNECT: {
        title: "Herstarten connectie",
        name: "iRose2 Herstarten connectie",
        description: "Deze test controleert of de iRose2 herstart kan worden.",
        instruction: ""
    },
    SLD4_CONNECT: {
        title: "Connectie",
        name: "SLD4 Connectie",
        description: "Deze test checkt of er een connectie is gemaakt met de fl-comserver.",
        instruction: ""
    },
    SLD4_RECONNECT: {
        title: "Herstarten connectie",
        name: "SLD4 Herstarten Connectie",
        description: "Deze test checkt of de fl-comserver herstart kan worden",
        instruction: ""
    },
    LD16_CONNECT: {
        title: "Connectie",
        name: "LD16 Connectie",
        description: "Deze test checkt of er een connectie is gemaakt met de LD16.",
        instruction: ""
    },
    LD16_RECONNECT: {
        title: "Herstarten connectie",
        name: "LD16 Herstarten Connectie",
        description: "Deze test checkt of de LD16 herstart kan worden",
        instruction: ""
    },
    FALCON_RADAR_PING: {
        title: "Ping",
        name: "Falcon Radar Ping Test",
        description: "Deze test checkt of het ip-adres van de falcon bereikbaar is.",
        instruction: ""
    },
    KOPER_KOPPELVLAK_PLC_PING: {
        title: "Ping PLC ",
        name: "Koper Koppelrack PLC Ping Test",
        description: "Deze test checkt of het ip-adres van de PLC van het koperkoppelrack bereikbaar is.",
        instruction: ""
    },
    KOPER_KOPPELVLAK_DSO_PING: {
        title: "Ping Ontvanger",
        name: "Koper Koppelrack Ontvanger Ping Test",
        description: "Deze test checkt of het ip-adres van de ontvanger van het koperkoppelrack bereikbaar is.",
        instruction: ""
    },
    KOPER_KOPPELVLAK_DSZ_PING: {
        title: "Ping Zender",
        name: "Koper Koppelrack Zender Ping Test",
        description: "Deze test checkt of het ip-adres van de zender van het koperkoppelrack bereikbaar is.",
        instruction: ""
    },
    PROFIBUS_GATEWAY_CONNECT: {
        title: "Profibusgateway Connectie",
        name: "Profibus Gateway Connectie",
        description: "Deze test checkt of er een connectie is gemaakt met de Profibusgateway.",
        instruction: ""
    },
    PROFIBUS_GATEWAY_RECONNECT: {
        title: "Herstarten connectie",
        name: "Profibus Gateway Herstarten connectie",
        description: "Deze test controleert of de profibus gateway herstart kan worden.",
        instruction: ""
    },
    UPS_COMMUNICATIE: {
        title: "Communicatie",
        name: "UPS Communicatie",
        description: "Deze test checkt of er communicatie is met de UPS.",
        instruction: ""
    },
    UPS_NOODVOEDING: {
        title: "Noodvoeding",
        name: "UPS Noodvoeding",
        description: "Deze test checkt of het systeem op de noodvoeding kan runnen.",
        instruction: "<br><br><b>Let op:</b><ul>" +
            "<li>Zet aan het begin van de test de UPS op noodstroom.</li>" +
            "<li>Zet na de test de UPS weer op hoofdstroom.</li></ul>",
    },
    UPS_HOOFDVOEDING: {
        title: "Hoofdvoeding",
        name: "UPS Hoofdvoeding",
        description: "Deze test checkt of de UPS functioneert terwijl het systeem op de hoofdvoeding runt.",
        instruction: ""
    }
};

let util = {

    getTestInformationMap(testType){
        return informationMap[testType];
    },

    sortTests(tests) {
        const testTypeOrder = Object.keys(informationMap);

        tests.sort(function (a, b) {
            return testTypeOrder.indexOf(a.testType) - testTypeOrder.indexOf(b.testType);
        });
    },

    parseDateTime(zonedDateTime) {
        let dtf = DateTimeFormatter.ofPattern("dd/MM/yyyy HH:mm:ss");
        return ZonedDateTime.parse(zonedDateTime).toLocalDateTime().format(dtf);
    },

    parseDate(zonedDateTime) {
        let dtf = DateTimeFormatter.ofPattern("dd/MM/yyyy");
        return ZonedDateTime.parse(zonedDateTime).toLocalDateTime().format(dtf);
    },

    /**
     * @param tests Array met alle tests
     * @returns {any[]} met de nodige informatie over de tests
     */
    getBaseTestsInformation(tests) {
        let testInformation = [];

        for (const test of tests) {

            const info = this.getTestInformationMap(test.testType);
            if (info === undefined || info === null) {
                console.log("TestInformationMap is null or undefined for test: ");
                console.log(JSON.stringify(test));
                continue;
            }
            const items = {
                "testType" : info.name ? info.name : "onbekend",
                "testDescription" : info.description ? info.description : "",
                "hardwareId" : info.hardwareId ? info.hardwareId : test.hardwareId,
            };

            if (test.executions.length < 1) {
                items["state"] = "Niet Uitgevoerd";
            } else {
                let testExecution = test.executions[test.executions.length - 1];
                items.state = this.parseExecutionState(testExecution.state);
            }

            testInformation.push(items);
        }

        return testInformation;
    },

    determineTestResult(tests){
        let testInformation = this.getBaseTestsInformation(tests);

        // Check if any test has failed
        if (testInformation.some(test => test.state !== 'Geslaagd')) {
            return 'Gefaald';
        } else {
            return 'Geslaagd';
        }
    },

    parseExecutionState(executionState) {
        switch(executionState) {
            case "INITIALIZED":
            case "RUNNING":
                return "Niet Afgerond";
            case "FAILED":
                return "Gefaald";
            case "SUCCESS":
                return "Geslaagd";
        }
    },
}
export default util;
