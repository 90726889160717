<template>
  <div> 
    <v-data-table
    id="falconRadarTable"
    :headers="headers"
    :items="falconRadars"
    class="elevation-1"
    item-key="id"
    :items-per-page="-1"
    :hide-default-footer="true"
    >
    <template v-slot:[`item.hardwareId`]="{ item }">
        <td :id="createElementId('id', item)" class="text-start">
            {{item.hardwareId}}
        </td>
    </template>
    <template v-slot:[`item.host`]="{ item }">
        <td :id="createElementId('host', item)" class="text-start">
            {{item.host}}
        </td>
    </template>
        <template v-slot:[`item.delete`]="{ item }">
            <v-icon
            :id="createElementId('delete', item)"
            small
            @click="deleteItem(item)"
            >
            mdi-delete
            </v-icon>
        </template>
    </v-data-table>
</div>
</template>

<script>
/**
  *  The ProfibusConfig component is a table where each row contains the configuration of a falcon radar.
  */
  export default {
    name: 'falconRadarConfig',

    data: () => ({
        
    }),

   /**
     *  vue-prop: falconRadars - the array of falcon radars where each falcon radar is a row in the table
     *  vue-prop: showHardwareId - boolean to determine whether the hardwareId should be shown as a column
     *  vue-prop: showDeleteButton - boolean to determine whether the delete button should be shown
     *  A single falcon radar config is used on the falcon radar page, above the tests of that specific falcon radar.
     *  A list of falcon radars config is used in the configuration page. 
     */
    props: {
     falconRadars: Array,
     showHardwareId: Boolean,
     showDeleteButton: Boolean
    },

    watch: {
        /**
         *  vue-event: falconRadarListUpdated - when the list of falcon radars is changed the new list is sent to the parent (Config.vue)
         */
        falconRadars: function (updatedList) {
            this.$emit('falconRadarListUpdated', updatedList);
        }
    },

    computed: {
      /**
        *  headers: columns displayed in the table
        *  The 'Id' column is only added to the table when the config shows a list of falcon radars.
        *  The delete icon is only added to the table when the config shows a list of falcon radars.
        */
        headers() {
            let headers = [
                {
                    text: 'Host', 
                    value: 'host', 
                    sortable: false 
                },
            ]

            if (this.showHardwareId) {
              headers.unshift({
                text: 'Id',
                align: 'start',
                sortable: false,
                value: 'hardwareId',
              });
            }
            if (this.showDeleteButton) {
              headers.push({
                text: '',
                sortable: false,
                value: 'delete',
              });
            }

            return headers
        }
    },

    methods: {
       /**
         *  Button click deleteItem
         *  Deletes a falcon radar from the array of falcon radars. 
         *  This removes that falcon radar config from the table.
         */
        deleteItem (item) {
            this.falconRadars.splice(this.falconRadars.indexOf(item), 1)
        },

        /**
         *  Method to create a unique HTML element id used for automatic testing
         */
        createElementId(element, item) {
            return element+"-"+item.hardwareId;
        }
    }
  }
</script>