<template><v-app>
  <div class="pa-6">
    <h1>Welkom bij de FAT testtooling!</h1>
    <div class="my-10">Begin een nieuwe testsessie.<br/>Of selecteer een testsessie uit de lijst om daarmee verder te gaan.</div>
    <v-btn id="createTestSessionBtn" unit-test-id="button" class="mt-0 mb-10" style="margin-left: 1px" depressed color="primary" @click="createTestSession">Nieuwe testsessie</v-btn>
    <TestSessionList />
  </div>
</v-app></template>

<script>
/**
 *  The Home page of the app. 
 *  Contains welcome text.
 *  component: TestSessionList - a list of previously created test sessions of the logged in user
 */
import TestSessionList from '@/components/TestSessionList.vue'

export default {
  name: 'home',
  components: {
    TestSessionList
  },
  methods: {
    /**
     *  Button click createTestSession
     *  Opens the configuration page to create a new test session configuration.
     */
    createTestSession() {
      this.$store.commit('setTestSessionId', '');
      this.$router.push('/testconfig');
    }
  }
}
</script>