import Vue from 'vue'
import config from './config';
import App from './App.vue'
import PageNotFound from './views/PageNotFound.vue'
import vuetify from './plugins/vuetify'
import { createProvider, onLogin } from './vue-apollo'
import router from './router'
import store from './store'
import VueLogger from 'vuejs-logger';
import Keycloak from 'keycloak-js';
import "./assets/style.css"

Vue.use(vuetify);
Vue.use(VueLogger);
Vue.config.productionTip = false

let initOptions = {
  url: config.VUE_APP_KEYCLOAK_URL,
  realm: config.VUE_APP_KEYCLOAK_REALM,
  clientId: config.VUE_APP_KEYCLOAK_CLIENT_ID,
  onLoad: 'login-required'
}

let keycloak = new Keycloak(initOptions);

Vue.$log.info("Initializing keycloak");
keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
  if (!auth) {
    window.location.reload();
    return;
  }

  if (!keycloak.hasRealmRole(config.VUE_APP_KEYCLOAK_REALM_ROLE_DEFAULT)) {
    alert('User not authorized.')
    keycloak.logout();
    return;
  }

  Vue.$log.info("Authenticated");

  onLogin(keycloak.token, null)

  const apolloProvider = createProvider()

  new Vue({
    vuetify,
    apolloProvider,
    router,
    store,
    render: h => h(App, { props: {
        keycloak: keycloak,
        config: config} })
  }).$mount('#app')

  //Token Refresh
  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        Vue.$log.info('Token refreshed' + refreshed);
        onLogin(keycloak.token, apolloProvider.defaultClient)
      } else {
        Vue.$log.info('Token not refreshed, valid for '
          + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }).catch(() => {
      Vue.$log.error('Failed to refresh token');
    });
  }, 6000)

}).catch((e) => {
  Vue.$log.error("Authenticated Failed", e);

  new Vue({
    render: h => h(PageNotFound)
  }).$mount('#app')

});