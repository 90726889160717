<template>
  <div> 
    <h2>ICP CON configuratie</h2>
    
    <IcpConConfig :icpcons="icpcons" :show-hardware-id="true" :show-delete-button="true" v-on:icpconsListUpdated="onListUpdated"/>

    <v-form ref="form" v-model="valid" class="mt-6">
        <v-row>
          <v-col cols="12" md="2">
            <v-text-field
              id="icpconId"
              v-model="icpconId"
              :rules="[idRules]"
              label="Id"
              required filled
              disabled
            ></v-text-field>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Het ICP CON id kan niet aangepast worden. Deze dient altijd icpcon-1 (ip 192.168.1.3) voor de eerste en icpcon-2 (ip 192.168.1.5) voor de tweede te zijn.</span>
            </v-tooltip>
          </span>
        </v-row>
        <v-row >
          <v-btn id="icpconAddBtn" :disabled="!valid" class="mt-5 ml-3" depressed color="primary" @click="addIcpCon">Toevoegen</v-btn>
        </v-row>
        <v-row class="mx-0 mt-6" >
          <span id="icpconTooManyItemsWarning" v-if="tooManyItems" class="warning--text">Maximum number of ICP CONs reached</span>
        </v-row>
    </v-form>
  </div>
</template>

<script>
/**
 *  The IcpConConfigForm component consists of a form with fields to configure an ICP CON.
 *  component: IcpConConfig - summary table of icpcons in the current iwks configuration
 */
import IcpConConfig from '@/components/IcpConConfig.vue'

export default {
    name: 'IcpConConfigForm',

    data: () => ({
        valid: true,
        icpconId: 'icpcon-1',
        icpcons: [],
        tooManyItems: false
    }),

    methods: {
      /**
       *  Button click addIcpCon
       *  Adds an icpcon with the config of values filled out in the form to the array of icpcons
       *  Resets the values of the form to the default values 
       */
      addIcpCon() {
        if (!this.$refs.form.validate()) return;
        const icpcon = {
          icpconId: this.icpconId,
        };
        this.icpcons.push(icpcon);
        this.resetIcpConInputFields();
      },

      /**
       *  Resets values of the form to default values
       */
      resetIcpConInputFields() {
        this.icpconId = this.newIcpConId();
      },
      /**
       *  Method used to generate a new unique id as default icpcon id in the config field
       */
      newIcpConId() {
        return this.icpcons.some(el => el.icpconId === "icpcon-1") ? "icpcon-2" : "icpcon-1";
      },

      /**
       *  The list of icpcons is modified from within this component (when adding icpcons to the list) and from the IcpConConfig component (when deleting icpcons from the list).
       *  Therefore, checking the size of the current number of icpcons in the list should be done in this method , which is a vue event triggered from its child component IcpConConfig.
       *  vue-event: icpconsListUpdated -  when the list of icpcons is updated in the IcpConConfig the new updated list is sent to the Config vue.
       */
      onListUpdated() {        
        this.$emit('icpconsListUpdated', this.icpcons);
        if (this.icpcons.length >= 2) {
          this.valid = false;
          this.tooManyItems = true;
        } else {
          this.valid = true;
          this.tooManyItems = false;
        }
        this.resetIcpConInputFields();
      },
      /**
       *  Method used to determine the rules for the id field in the form.
       *  Other rules are determined as arrays in data objects, but there you cannot access other data objects and we need to access the icpcons array
       */
      idRules( value ) {
        if (value.length === 0 || value === "" ) {
          return "Id is required";
        }

        if (this.icpcons.some(el => el.icpconId === value)) {
          return "Identifier must be unique";
        }

        return true;
      }
    },
    components: {
      IcpConConfig
    }
  }
</script>