<template><v-app>
  <div>
    <div class="pa-6">        
      <h2 class="mb-4">Release Notes</h2>

      <div v-html="compiledMarkdown"></div>

    </div>
  </div>
</v-app></template>

<script>
import marked from 'marked';

export default {
    data() {
        return {
            releaseNotesContent: ''
        };
    },
    computed: {
        compiledMarkdown() {
            return marked.parse(this.releaseNotesContent);
        }
    },
    mounted() {
        this.fetchReleaseNotes();
    },
    methods: {
        fetchReleaseNotes() {
            fetch('/functional-release-notes.md')
                .then(response => response.text())
                .then(content => {
                    this.releaseNotesContent = content;
                })
                .catch(error => {
                    console.error('Error fetching release notes: ', error);
                });
        }
    }
};
</script>