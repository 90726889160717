import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Config from '../views/Config.vue'
import PageNotFound from '../views/PageNotFound.vue'
import ReleaseNotes from '../views/ReleaseNotes.vue'
import Testresultaten from "@/views/Testresultaten";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/amttesten',
    name: 'AMT Testen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AMT.vue')
  },
  {
    path: '/lanswitchtesten',
    name: 'LAN Switch Testen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LANSwitch.vue')
  },
  {
    path: '/icpcontesten',
    name: 'ICP CON Testen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/IcpCon.vue')
  },
  {
    path: '/irosetesten',
    name: 'iRose Testen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/iRose.vue')
  },
  {
    path: '/sld4testen',
    name: 'Sld4 Testen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Sld4.vue')
  },
  {
    path: '/ld16testen',
    name: 'LD16 Testen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Ld16.vue')
  },
  {
    path: '/falconradartesten',
    name: 'Falcon Radar Testen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FalconRadar.vue')
  },
  {
    path: '/koperkoppelracktesten',
    name: 'Koper Koppelrack Testen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/KoperKoppelvlak.vue')
  },
  {
    path: '/profibustesten',
    name: 'Profibus Testen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Profibus.vue')
  },
  {
    path: '/upstesten',
    name: 'UPS Testen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Ups.vue')
  },
  {
    path: '/testconfig',
    name: 'Configuratie',
    component: Config
  },
  {
    path: '/testresultaten',
    name: 'Testresultaten',
    component: Testresultaten
  },

  {
    path: '/releasenotes',
    name: 'Release Notes',
    component: ReleaseNotes
  },

  { 
    path: '/404', 
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
