import * as configOverride from 'config-override';

export default {
  VUE_APP_KEYCLOAK_URL: 'http://localhost:8082',
  VUE_APP_KEYCLOAK_REALM: 'hwtest',
  VUE_APP_KEYCLOAK_REALM_ROLE_DEFAULT: 'default-roles-hwtest',
  VUE_APP_KEYCLOAK_CLIENT_ID: 'iwks-fattool',
  VUE_APP_GRAPHQL_HTTP: 'http://localhost:8081/graphql',
  VUE_APP_GRAPHQL_WS: 'ws://localhost:8081/subscriptions',
  ...configOverride
}
