<template>
  <div>
    <h2>LD16 configuratie</h2>

    <Ld16Config :ld16s="ld16s" :show-hardware-id="true" :show-delete-button="true" v-on:ld16sListUpdated="onListUpdated"/>

    <v-form ref="form" v-model="valid" class="mt-6">
        <v-row>
          <v-col cols="12" md="2">
            <v-text-field
              id="ld16Id"
              v-model="hardwareId"
              :rules="[idRules]"
              :counter="20"
              label="Id"
              required filled
            ></v-text-field>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>ID moet uniek zijn. Het ID wordt gebruikt om Ld16s te onderscheiden en te herkennen in het testrapport.</span>
            </v-tooltip>
          </span>

          <v-col cols="12" md="2">
            <v-select
              id="ld16Host"
              v-model="host"
              :rules="hostRules"
              :items="hostOptions"
              label="Ld16 host"
              required filled
            ></v-select>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Het IP adres van de LD16 is op te zoeken in de geleverde IP lijst.<br>.41 is hoofdbaanweg rechts<br>.42 is verbindingsweg rechts<br>.43 is hoofdbaanweg links<br>.44 is verbindingsweg links</span>
            </v-tooltip>
          </span>

          <v-col cols="12" md="2">
            <v-select
            id="ld16Port"
            filled
            v-model="port"
            :items="portOptions"
            :rules="portRules"
            :return-object="false"
            label="poort"
            required >
          </v-select>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>De Ld16 TCP poort voor de communicatie met de Ld16. De poort staat default goed ingevuld.</span>
            </v-tooltip>
          </span>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-select
                id="ld16IcpconId"
                filled
                v-model="icpconId"
                :items="icpconIdOptions"
                :return-object="false"
                label="ICP CON ID"
                required >
            </v-select>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Het ID van de ICP CON waar de LD16 op aangesloten is. In vrijwel alle gevallen zal dit icpcon-1 zijn (dit is de ICP CON met ip 192.168.1.3).</span>
            </v-tooltip>
          </span>
          <v-col cols="12" md="2">
            <v-text-field
              id="ld16IcpconRelaisNummer"
              v-model="icpconRelaisNummer"
              :rules="icpconRelaisNummerRules"
              label="ICP CON Relais Nummer"
              required filled
            ></v-text-field>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Duidt aan welk relais van de ICP CON gebruikt wordt om de LD16 te herstarten. Dit is te vinden in het kastenpakket.</span>
            </v-tooltip>
          </span>
        </v-row>

        <v-row >
          <v-btn id="ld16AddBtn" :disabled="!valid" class="mt-5 ml-3" depressed color="primary" @click="addLd16">Toevoegen</v-btn>
        </v-row>
        <v-row class="mx-0 mt-6" >
          <span id="ld16TooManyItemsWarning" v-if="tooManyItems" class="warning--text">Maximum number of LD16s reached</span>
        </v-row>
    </v-form>
  </div>
</template>

<script>
/**
  *  The Ld16ConfigForm component consists of a form with fields to configure an LD16.
  *  component: Ld16Config - summary table of ld16s in the current iwks configuration
  */
import Ld16Config from '@/components/Ld16Config.vue'

  export default {
    name: 'Ld16ConfigForm',

    /**
     *  valid: boolean to store whether the form is valid (i.e. has no errors)
     *  hardwareId: string containing the ld16 ID
     *  host: string containing the host address
     *  hostOptions: the possible host options
     *  hostRules: rules used to determine whether the field is a valid host
     *  port: string containing the port number
     *  portOptions: array with possible default values for the iWKS port that the ld16 sends messages to
     *  portRules: rules used to determine whether the field is a valid port number
     *  lusparen: integer containing the number of lusparen
     *  lusparenRules: rules used to determine whether the field is a valid number of lusparen value
     *  ld16s: array of ld16s currently added to the configuration
     *  tooManyItems: boolean used to show an error message when the maximum number of ld16s in the list is reached
     */
    data: () => ({
        valid: true,
        hardwareId: 'ld16-1',
        host: '192.168.1.82',
        hostOptions: ['192.168.1.82', '192.168.1.83', '192.168.1.84', '192.168.1.85'],
        hostRules: [
            v => !!v || 'Host is required',
            v => /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(v) || 'Host must be a valid ip address',
        ],
        port: '7032',
        portOptions: ['7032', '7033', '7034', '7035'],
        portRules: [
            v => !!v || 'Port is required',
            v => Number.isInteger(Number(v)) || "The value must be an integer number",
            v => Number(v) > 0 && Number(v) < 65536|| "The value must be a valid port number",
        ],
        lusparen: 1,
        lusparenRules: [
            v => !!v || 'Field is required',
            v => Number.isInteger(Number(v)) || "The value must be an integer number",
            v => Number(v) >= 1 && Number(v) <= 8|| "Valid values are 1-8",
        ],
        ld16s: [],
        tooManyItems: false,
        icpconId: 'icpcon-1',
        icpconIdOptions: ['icpcon-1', 'icpcon-2'],
        icpconRelaisNummer: 1,
        icpconRelaisNummerRules: [
            v => v!=="" || 'Field is required',
            v => Number.isInteger(Number(v)) || "The value must be an integer number",
            v => Number(v) >= 0 && Number(v) <= 7|| "Valid values are 0-7",
        ]
    }),

    methods: {
      /**
       *  Button click addLd16
       *  Adds an LD16 with the config of values filled out in the form to the array of LD16s
       *  Resets the values of the form to the default values
       */
      addLd16() {
        if (!this.$refs.form.validate()) return;
        const ld16 = {
          hardwareId: this.hardwareId,
          host: this.host,
          port: this.port,
          lusparen: this.lusparen,
          icpconId: this.icpconId,
          icpconRelaisNummer: this.icpconRelaisNummer,
        };
        this.ld16s.push(ld16);
        this.resetLd16InputFields();
      },

      /**
       *  Resets values of the form to default values
       */
      resetLd16InputFields() {
        this.hardwareId = this.newLd16Id();
        this.host = '192.168.1.82';
        this.port = '7032';
        this.lusparen = 1;
        this.icpconId = "icpcon-1"
        this.icpconRelaisNummer = 1;
      },
      /**
       *  Method used to generate a new unique id as default LD16 id in the config field
       */
      newLd16Id() {
        var nr = 0;
        var id = "ld16-1";
        while (this.ld16s.some(el => el.hardwareId === id)) {
          nr++;
          id = 'ld16-' + nr.toString();
        }
        return id;
      },

      /**
       *  The list of LD16s is modified from within this component (when adding LD16s to the list) and from the Ld16Config component (when deleting LD16s from the list).
       *  Therefore, checking the size of the current number of LD16s in the list should be done in this method , which is a vue event triggered from its child component Ld16Config.
       *  vue-event: ld16sListUpdated -  when the list of LD16s is updated in the Ld16Config the new updated list is sent to the Config vue.
       */
      onListUpdated() {
        this.$emit('ld16sListUpdated', this.ld16s);
        if (this.ld16s.length >= 8) {
          this.valid = false;
          this.tooManyItems = true;
        } else {
          this.valid = true;
          this.tooManyItems = false;
        }
      },
      /**
       *  Method used to determine the rules for the id field in the form.
       *  Other rules are determined as arrays in data objects, but there you cannot access other data objects and we need to access the ld16s array
       */
      idRules( value ) {
        if (value.length == 0 || value === "" ) {
          return "Id is required";
        }

        if (value.length > 20 ) {
          return "The value must be less than 20 characters"
        }

        if (this.ld16s.some(el => el.hardwareId === value)) {
          return "Identifier must be unique";
        }

        return true;
      }
    },
    components: {
      Ld16Config
    }
  }
</script>
