<template>
  <div> 
    <v-data-table
    id="profibusTable"
    :headers="headers"
    :items="profibuses"
    class="elevation-1"
    item-key="id"
    :items-per-page="-1"
    :hide-default-footer="true"
    >
        <template v-slot:[`item.profibusId`]="{ item }">
            <td :id="createElementId('id', item)" class="text-start">
                {{item.profibusId}}
            </td>
        </template>
        <template v-slot:[`item.host`]="{ item }">
            <td :id="createElementId('host', item)" class="text-start">
                {{item.host}}
            </td>
        </template>
        <template v-slot:[`item.icpconId`]="{ item }">
            <td :id="createElementId('icpconId', item)" class="text-start">
                {{item.icpconId}}
            </td>
        </template>
        <template v-slot:[`item.icpconRelaisNummer`]="{ item }">
            <td :id="createElementId('icpconRelaisNummer', item)" class="text-start">
                {{item.icpconRelaisNummer}}
            </td>
        </template>
        <template v-slot:[`item.delete`]="{ item }">
            <v-icon
            :id="createElementId('delete', item)"
            small
            @click="deleteItem(item)"
            >
            mdi-delete
            </v-icon>
        </template>
    </v-data-table>
</div>
</template>

<script>
/**
  *  The ProfibusConfig component is a table where each row contains the configuration of a profibus.
  */
  export default {
    name: 'profibusConfig',

    data: () => ({
        
    }),

   /**
     *  vue-prop: profibuses - the array of profibuses where each profibus is a row in the table
     *  vue-prop: showHardwareId - boolean to determine whether the hardwareId should be shown as a column
     *  vue-prop: showDeleteButton - boolean to determine whether the delete button should be shown
     *  A single profibus config is used on the profibus page, above the tests of that specific profibus.
     *  A list of profibuses config is used in the configuration page. 
     */
    props: {
     profibuses: Array,
     showHardwareId: Boolean,
     showDeleteButton: Boolean
    },

    watch: {
        /**
         *  vue-event: profibusListUpdated - when the list of profibuses is changed the new list is sent to the parent (Config.vue)
         */
        profibuses: function (updatedList) {
            this.$emit('profibusListUpdated', updatedList);
        }
    },

    computed: {
        headers() {
            let headers = [
                {
                    text: 'Host', 
                    value: 'host', 
                    sortable: false 
                },
                {
                    text: 'ICP CON ID',
                    value: 'icpconId',
                    sortable: false,
                },
                {
                    text: 'ICP CON Relais Nummer',
                    value: 'icpconRelaisNummer',
                    sortable: false
                }
            ]

            var showMus1InConfig = false;
            var showMus2InConfig = false;

            this.profibuses.forEach(element => {
                if (element.mussen.length>0){
                    showMus1InConfig = true;
                }
                if (element.mussen.length>1){
                    showMus2InConfig = true;
                }
            })
            if(showMus1InConfig) {
                headers.push(
                    { 
                        text: 'Mus1 rotatie', 
                        value: 'mussen[0].rotatiePaneel', 
                        sortable: false,
                    },
                    { 
                        text: 'Mus1 slave', 
                        value: 'mussen[0].pbSlaveAddress', 
                        sortable: false,
                    },
                )
            }

            if(showMus2InConfig) {
                headers.push(
                    { 
                        text: 'Mus2 rotatie', 
                        value: 'mussen[1].rotatiePaneel', 
                        sortable: false,
                    },
                    { 
                        text: 'Mus1 slave', 
                        value: 'mussen[1].pbSlaveAddress', 
                        sortable: false,
                    },
                )
            }

            if (this.showHardwareId) {
              headers.unshift({
                text: 'Id',
                align: 'start',
                sortable: false,
                value: 'profibusId',
              });
            }
            if (this.showDeleteButton) {
              headers.push({
                text: '',
                sortable: false,
                value: 'delete',
              });
            }

            return headers
        }
    },

    methods: {
       /**
         *  Button click deleteItem
         *  Deletes a profibus from the array of profibuses. 
         *  This removes that profibus config from the table.
         */
        deleteItem (item) {
            this.profibuses.splice(this.profibuses.indexOf(item), 1)
        },

        /**
         *  Method to create a unique HTML element id used for automatic testing
         */
        createElementId(element, item) {
            return element+"-"+item.profibusId;
        }
    }
  }
</script>