<template>
  <div> 

    <h2>Falcon Radar configuratie</h2>

    <FalconRadarConfig :falconRadars="falconRadars" :show-hardware-id="true" :show-delete-button="true" v-on:falconRadarListUpdated="onListUpdated"/>

    <v-form ref="form" v-model="valid" class="mt-6">
        <v-row>
          <v-col cols="12" md="2">
            <v-text-field
              id="hardwareId"
              v-model="hardwareId"
              :rules="[idRules]"
              :counter="20"
              label="Id"
              required
              filled
            ></v-text-field>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>ID moet uniek zijn. Het ID wordt gebruikt om falcon radars te onderscheiden en te herkennen in het testrapport.</span>
            </v-tooltip>
          </span>
  
          <v-col cols="12" md="2">
            <v-select
              id="falconRadarHost"
              v-model="host"
              :rules="hostRules"
              :items="hostOptions"
              label="Host"
              required filled
            ></v-select>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Het IP adres van de falcon radar is op te zoeken in de geleverde IP lijst.<br>.21 is hoofdbaanweg rechts<br>.22 is hoofdbaanweg links<br>.23 is verbindingsweg rechts<br>.24 is verbindingsweg links</span>
            </v-tooltip>
          </span>
        </v-row>

        <v-row >
          <v-btn id="falconRadarAddBtn" :disabled="!valid" class="mt-5 ml-3" depressed color="primary" @click="addFalconRadar">Toevoegen</v-btn>
        </v-row>
        <v-row class="mx-0 mt-6" >
          <span id="falconRadarTooManyItemsWarning" v-if="tooManyItems" class="warning--text">Maximum number of falcon radars reached</span>
        </v-row>
    </v-form>        
  </div>
</template>

<script>
/**
  *  The FalconRadarConfigForm component consists of a form with fields to configure a falcon radar.
  *  component: FalconRadarConfig - summary table of falcon radars in the current iwks configuration
  */
import FalconRadarConfig from '@/components/FalconRadarConfig.vue'

  export default {
    name: 'falconRadarConfigForm',

   /**
     *  valid: boolean to store whether the form is valid (i.e. has no errors)
     *  hardwareId: string containing the falcon radar hardware ID
     *  host: string containing the host address
     *  hostOptions: the possible host options
     *  hostRules: rules used to determine whether the field is a valid host
     *  falconRadars: array of falcon radars currently added to the configuration
     *  tooManyItems: boolean used to show an error message when the maximum number of falcon radars in the list is reached
     */
    data: () => ({
        valid: true,
        hardwareId: 'falcon1',
        host: '192.168.1.51',
        hostOptions: ['192.168.1.51', '192.168.1.52', '192.168.1.53', '192.168.1.54'],
        hostRules: [
            v => !!v || 'Host is required',
            v => /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(v) || 'Host must be a valid ip address',
        ],
        requiredRules: [
            v => !!v || 'Field is required',
        ],
        
        falconRadars: [],
        tooManyItems: false
    }),

    methods: {
      addFalconRadar() {
        if (!this.$refs.form.validate()) return;
        const falconRadar = {
          hardwareId: this.hardwareId,
          host: this.host,
        };
        this.falconRadars.push(falconRadar);
        this.resetFalconRadarInputFields();
      },

      resetFalconRadarInputFields() {
        this.hardwareId = this.newHardwareId();
        this.host = '192.168.1.51';
      },
      /**
       *  Method used to generate a new unique id as default falcon radar id in the config field
       */
      newHardwareId() {
        let nr = 0;
        let id = "falcon1";
        while (this.falconRadars.some(el => el.hardwareId === id)) {
          nr++;
          id = 'falcon' + nr.toString();
        }
        return id;
      },

      /**
       *  The list of falcon radars is modified from within this component (when adding a falconRadar to the list) and from the FalconRadarConfig component (when deleting falcon radars from the list).
       *  Therefore, checking the size of the current number of falcon radars in the list should be done in this method, which is a vue event triggered from its child component FalconRadarConfig.
       *  vue-event: falconRadarListUpdated -  when the list of falcon radars is updated in the FalconRadarConfig the new updated list is sent to the Config vue.
       */
      onListUpdated() {
        this.$emit('falconRadarListUpdated', this.falconRadars);
        if (this.falconRadars.length >= 4) {
          this.valid = false;
          this.tooManyItems = true;
        } else {
          this.valid = true;
          this.tooManyItems = false;
        }
      },
      /**
       *  Method used to determine the rules for the id field in the form.
       *  Other rules are determined as arrays in data objects, but there you cannot access other data objects and we need to access the falconRadars array
       */
      idRules( value ) {
        if (value.length == 0 || value === "" ) {
          return "Id is required";
        }

        if (value.length > 20 ) {
          return "The value must be less than 20 characters"
        }

        if (this.falconRadars.some(el => el.hardwareId === value)) {
          return "Identifier must be unique";
        }

        return true;
      }
    },
    components: {
      FalconRadarConfig
    }
  }
</script>