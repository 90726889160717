<template><v-app>
  <div>
    <h1>404 page not found</h1>
    <button class="button" depressed color="primary" @click="tryAgain">Probeer opnieuw</button>
  </div>
</v-app></template>

<script>
/**
 *  Page shown when there is no keycloak connection. 
 */

export default {
  name: '404',
  methods: {
    /**
     *  Button click tryAgain
     *  Reloads the window and application to initiate the keycloak connection again.
     */
    tryAgain() {
        window.location.reload();
    }
  }
}
</script>

<style scoped>
  div {
    margin: 50px;
}
.button {
  background-color: #1976d2;
  border: none;
  color: white;
  padding: 0px 16px;
  height: 36px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: .875rem;
  margin-top: 20px;
  cursor: pointer;
}

</style>