<template>
  <div>
    <h2>Yunex C3 koper koppelrack configuratie</h2>

    <KoperKoppelvlakConfig
      :koperKoppelvlakken="koperKoppelvlakken"
      :show-hardware-id="false"
      :show-delete-button="true"
      v-on:koperKoppelvlakListUpdated="onListUpdated"
    />

    <v-form ref="form" v-model="valid" class="mt-6">
      <v-row>
        <v-col cols="12" md="2">
          <v-text-field
            id="koperKoppelvlakHostPLC"
            v-model="hostPLC"
            label="PLC Host"
            required
            filled
            disabled
          ></v-text-field>
        </v-col>
        <span class="mt-3">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on"
                >mdi-information-outline</v-icon
              >
            </template>
            <span
              >Het IP adres van de PLC van het koperkoppelvlak kan niet aangepast worden.
              Deze dient altijd 192.168.1.55 te zijn.</span
            >
          </v-tooltip>
        </span>

        <v-col cols="12" md="2">
          <v-select
            id="koperKoppelvlakHostDSO"
            v-model="hostDSO"
            :items="hostDSOOptions"
            label="DSO Host"
            required
            filled
          ></v-select>
        </v-col>
        <span class="mt-3">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on"
                >mdi-information-outline</v-icon
              >
            </template>
            <span
              >Het IP adres van de ontvanger van het koperkoppelvlak. Deze is optioneel.
              Deze dient altijd 192.168.1.56 te zijn.</span
            >
          </v-tooltip>
        </span>

        <v-col cols="12" md="2">
          <v-select
            id="koperKoppelvlakHostDSZ"
            v-model="hostDSZ"
            :items="hostDSZOptions"
            label="Host DSZ"
            required
            filled
          ></v-select>
        </v-col>
        <span class="mt-3">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on"
                >mdi-information-outline</v-icon
              >
            </template>
            <span
              >Het IP adres van de zender van het koperkoppelvlak. Deze is optioneel. Deze
              dient altijd 192.168.1.57 te zijn.</span
            >
          </v-tooltip>
        </span>
      </v-row>

      <v-row>
        <v-btn
          id="koperKoppelvlakAddBtn"
          :disabled="!valid"
          class="mt-5 ml-3"
          depressed
          color="primary"
          @click="addKoperKoppelvlak"
          >Toevoegen</v-btn
        >
      </v-row>
      <v-row class="mx-0 mt-6">
        <span
          id="koperKoppelvlakTooManyItemsWarning" v-if="tooManyItems" class="warning--text">Maximum number of koperkoppelvlakken reached</span
        >
      </v-row>
    </v-form>
  </div>
</template>

<script>
/**
 *  The KoperKoppelvlakConfigForm component consists of a form with fields to configure a koperkoppelvlak.
 *  component: KoperKoppelvlakConfig - summary table of koperkoppelvlakken in the current iwks configuration
 */
import KoperKoppelvlakConfig from "@/components/KoperKoppelvlakConfig.vue";

export default {
  name: "koperKoppelvlakConfigForm",

  /**
   *  valid: boolean to store whether the form is valid (i.e. has no errors)
   *  host: string containing the host address
   *  hostOptions: the possible host options
   *  hostRules: rules used to determine whether the field is a valid host
   *  koperKoppelvlakken: array of koperkoppelvlakken currently added to the configuration
   *  tooManyItems: boolean used to show an error message when the maximum number of koperkoppelvlakken in the list is reached. In this case just 1.
   */
  data: () => ({
    valid: true,
    hostPLC: "192.168.1.55",
    hostDSO: "192.168.1.56",
    hostDSZ: "192.168.1.57",
    hostDSOOptions: ["192.168.1.56", "---"],
    hostDSZOptions: ["192.168.1.57", "---"],
    requiredRules: [(v) => !!v || "Field is required"],
    koperKoppelvlakken: [],
    tooManyItems: false,
  }),

  methods: {
    addKoperKoppelvlak() {
      if (!this.$refs.form.validate()) return;

      var hostDSOlocal = this.hostDSO === "---" ? null : this.hostDSO;
      var hostDSZlocal = this.hostDSZ === "---" ? null : this.hostDSZ;

      const koperKoppelvlak = {
        hostPLC: this.hostPLC,
        hostDSO: hostDSOlocal,
        hostDSZ: hostDSZlocal,
      };

      this.koperKoppelvlakken.push(koperKoppelvlak);
      this.resetKoperkoppelvlakInputFields();
    },

    resetKoperkoppelvlakInputFields() {
      this.hostPLC = "192.168.1.55";
    },

    /**
     *  The list of koperkoppelvlakken is modified from within this component (when adding a koperkoppelvlak to the list) and from the KoperKoppelvlakConfig component (when deleting koperkoppelvlakken from the list).
     *  Therefore, checking the size of the current number of koperkoppelvlakken in the list should be done in this method, which is a vue event triggered from its child component KoperKoppelvlakConfig.
     *  vue-event: koperKoppelvlakListUpdated -  when the list of koperkoppelvlakken is updated in the KoperKoppelvlakConfig the new updated list is sent to the Config vue.
     */
    onListUpdated() {
      this.$emit("koperKoppelvlakListUpdated", this.koperKoppelvlakken);
      if (this.koperKoppelvlakken.length >= 1) {
        this.valid = false;
        this.tooManyItems = true;
      } else {
        this.valid = true;
        this.tooManyItems = false;
      }
    },
  },
  components: {
    KoperKoppelvlakConfig,
  },
};
</script>
