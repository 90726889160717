<template>
  <div>
    <v-data-table
    id="icpconsTable"
    :headers="headers"
    :items="icpcons"
    class="elevation-1"
    item-key="id"
    :items-per-page="-1"
    :hide-default-footer="true"
    >
    <template v-slot:[`item.icpconId`]="{ item }">
        <td :id="createElementId('id', item)" class="text-start">
            {{item.icpconId}}
        </td>
    </template>
    <template v-slot:[`item.delete`]="{ item }">
        <v-icon
        :id="createElementId('delete', item)"
        small
        @click="deleteItem(item)"
        >
        mdi-delete
        </v-icon>
        </template>
    </v-data-table>
  </div>
</template>

<script>
   /**
    *  The IcpConConfig component is a table where each row contains the configuration of an icpcon
    */
  export default {
    name: 'IcpConConfig',

    data: () => ({
       /**
        *  headers: columns displayed in the table
        */
        headers: [],
    }),

    /**
     *  vue-prop: icpcons - the array of icpcons where each icpcon is a row in the table
     *  vue-prop: showHardwareId - boolean to determine whether the icpconId should be shown as a column
     *  vue-prop: showDeleteButton - boolean to determine whether the delete button should be shown
     *  A single icpcon config is used on the icpcon page, above the tests of that specific icpcon.
     *  A list of icpcon config is used in the configuration page.
     */
    props: {
        icpcons: Array,
        showHardwareId: Boolean,
        showDeleteButton: Boolean
    },

    watch: {
        /**
         *  vue-event: icpconsListUpdated - when the list of icpcons is changed the new list is sent to parent (Config.vue)
         */
        icpcons: function (updatedList) {
            this.$emit('icpconsListUpdated', updatedList);
        }
    },

    /**
     *  The 'Id' column is only added to the table when the config shows a list of icpcons.
     *  The delete icon is only added to the table when the config shows a list of icpcons.
     */
    created () {
        if (this.showHardwareId) {
          this.headers.unshift({
            text: 'Id',
            align: 'start',
            sortable: false,
            value: 'icpconId',
          });
        }
        if (this.showDeleteButton) {
          this.headers.push({
            text: '',
            sortable: false,
            value: 'delete',
          });
        }
    },

    methods: {
        /**
         *  Button click deleteItem
         *  Deletes an icpcon from the array of icpcons.
         *  This removes that icpcon config from the table.
         */
        deleteItem (item) {
            this.icpcons.splice(this.icpcons.indexOf(item), 1)
        },

        /**
         *  Method to create a unique HTML element id used for automatic testing
         */
        createElementId(element, item) {
            return element+"-"+item.icpconId;
        }
    }
  }
</script>