<template>
  <div> 
    <v-data-table
    id="koperKoppelvlakTable"
    :headers="headers"
    :items="koperKoppelvlakken"
    class="elevation-1"
    item-key="id"
    :items-per-page="-1"
    :hide-default-footer="true"
    >
    <template v-slot:[`item.hostPLC`]="{ item }">
        <td :id="createElementId('host-PLC', item)" class="text-start">
            {{item.hostPLC}}
        </td>
    </template>
    <template v-slot:[`item.hostDSO`]="{ item }">
        <td :id="createElementId('host-DSO', item)" class="text-start">
            {{item.hostDSO}}
        </td>
    </template>
    <template v-slot:[`item.hostDSZ`]="{ item }">
        <td :id="createElementId('host-DSZ', item)" class="text-start">
            {{item.hostDSZ}}
        </td>
    </template>
        <template v-slot:[`item.delete`]="{ item }">
            <v-icon
            :id="createElementId('delete')"
            small
            @click="deleteItem(item)"
            >
            mdi-delete
            </v-icon>
        </template>
    </v-data-table>
</div>
</template>

<script>
/**
  *  The KoperkoppelvlakConfig component is a table where each row contains the configuration of a koperkoppelvlak.
  */
  export default {
    name: 'koperKoppelvlakConfig',

    data: () => ({
        
    }),

   /**
     *  vue-prop: koperKoppelvlak - the array of koperKoppelvlakken where each koperKoppelvlak is a row in the table. But in this case can only be 1 item.
     *  vue-prop: showHardwareId - boolean to determine whether the hardwareId should be shown as a column
     *  vue-prop: showDeleteButton - boolean to determine whether the delete button should be shown
     *  A single koperkoppelvlak config is used on the koperkoppelvlak page, above the tests of that specific koperkoppelvlak.
     *  A list of koperkoppelvlakken config is used in the configuration page. In this case that is maximum 1.
     */
    props: {
     koperKoppelvlakken: Array,
     showHardwareId: Boolean,
     showDeleteButton: Boolean
    },

    watch: {
        /**
         *  vue-event: koperKoppelvlakkenListUpdated - when the list of koperkoppelvlakken is changed the new list is sent to the parent (Config.vue)
         */
        koperKoppelvlakken: function (updatedList) {
            this.$emit('koperKoppelvlakListUpdated', updatedList);
        }
    },

    computed: {
      /**
        *  headers: columns displayed in the table
        *  The 'Id' column is only added to the table when the config shows a list of koperkoppelvlakken.
        *  The delete icon is only added to the table when the config should be able to be deleted from the list.
        */
        headers() {
            let headers = [
                {
                    text: 'Host PLC', 
                    value: 'hostPLC', 
                    sortable: false 
                },
                {
                    text: 'Host DSO', 
                    value: 'hostDSO', 
                    sortable: false 
                },
                {
                    text: 'Host DSZ', 
                    value: 'hostDSZ', 
                    sortable: false 
                },
            ]

            if (this.showHardwareId) {
              headers.unshift({
                text: 'Id',
                align: 'start',
                sortable: false,
                value: 'hardwareId',
              });
            }
            if (this.showDeleteButton) {
              headers.push({
                text: '',
                sortable: false,
                value: 'delete',
              });
            }

            return headers
        }
    },

    methods: {
       /**
         *  Button click deleteItem
         *  Deletes a koperkoppelvlak from the array of koperkoppelvlakken. 
         *  This removes that koperkoppelvlak config from the table.
         */
        deleteItem (item) {
            this.koperKoppelvlakken.splice(this.koperKoppelvlakken.indexOf(item), 1)
        },

        /**
         *  Method to create a unique HTML element id used for automatic testing
         */
        createElementId(element) {
            return element;
        }
    }
  }
</script>