var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"ld16sTable","headers":_vm.headers,"items":_vm.ld16s,"item-key":"id","items-per-page":-1,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.hardwareId",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-start",attrs:{"id":_vm.createElementId('id', item)}},[_vm._v(" "+_vm._s(item.hardwareId)+" ")])]}},{key:"item.host",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-start",attrs:{"id":_vm.createElementId('host', item)}},[_vm._v(" "+_vm._s(item.host)+" ")])]}},{key:"item.port",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-start",attrs:{"id":_vm.createElementId('port', item)}},[_vm._v(" "+_vm._s(item.port)+" ")])]}},{key:"item.icpconId",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-start",attrs:{"id":_vm.createElementId('icpconId', item)}},[_vm._v(" "+_vm._s(item.icpconId)+" ")])]}},{key:"item.icpconRelaisNummer",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-start",attrs:{"id":_vm.createElementId('icpconRelaisNummer', item)}},[_vm._v(" "+_vm._s(item.icpconRelaisNummer)+" ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"id":_vm.createElementId('delete', item),"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }