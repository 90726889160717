<template>
  <div> 
    <h2>iRose configuratie</h2>
    
    <iRoseConfig :i-roses="iRoses" :show-hardware-id="true" :show-delete-button="true" v-on:iRosesListUpdated="onListUpdated"/>

    <v-form ref="form" v-model="valid" class="mt-6">
        <v-row>
          <v-col cols="12" md="2">
            <v-text-field
              id="iRoseId"
              v-model="hardwareId"
              :rules="[idRules]"
              :counter="20"
              label="Id"
              required filled
            ></v-text-field>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>ID moet uniek zijn. Het ID wordt gebruikt om iRoses te onderscheiden en te herkennen in het testrapport.</span>
            </v-tooltip>
          </span>
  
          <v-col cols="12" md="2">
            <v-select
              id="iRoseHost"
              v-model="host"
              :rules="hostRules"
              :items="hostOptions"
              label="iRose host"
              required filled
              @change="updateIWksPort($event)"
            ></v-select>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Het IP adres van de iRose is op te zoeken in de geleverde IP lijst.<br>.41 is hoofdbaanweg rechts<br>.42 is verbindingsweg rechts<br>.43 is hoofdbaanweg links<br>.44 is verbindingsweg links</span>
            </v-tooltip>
          </span>
  
          <v-col cols="12" md="2">
            <v-select
            id="iRoseIwksPort"  
            filled
            v-model="port"
            :items="portOptions"
            :rules="portRules"
            :return-object="false"
            label="iWKS poort"
            required >
          </v-select>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>De iWKS poort voor de communicatie met de iRose. De poort staat default goed ingevuld en is afhankelijk van het geselecteerde IP adres voor de iRose host.<br>.41 met poort 12321<br>.42 met poort 12322<br>.43 met poort 12323<br>.44 met poort 12324</span>
            </v-tooltip>
          </span>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-text-field
              id="iRoseSshPort"
              v-model="sshPort"
              :rules="portRules"
              label="iRose Ssh poort"
              required filled
            ></v-text-field>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>De SSH configuratie om via SSH informatie van de iRose op te halen zoals kernel informatie.<br>Als de configuratie van de iRose niet is aangepast hoeven deze waarden niet worden aangepast.<br>poort: 22<br>username: root<br>wachtwoord: admin</span>
            </v-tooltip>
          </span>

          <v-col cols="12" md="2" class="mr-3">
            <v-text-field
              id="iRoseSshUsername"
              v-model="sshUsername"
              :rules="requiredRules"
              label="iRose Ssh username"
              required filled
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              id="iRoseSshPassword"
              v-model="sshPassword"
              :rules="requiredRules"
              label="iRose Ssh password"
              required filled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="2">
            <v-select
                id="iRoseIcpconId"
                filled
                v-model="icpconId"
                :items="icpconIdOptions"
                :return-object="false"
                label="ICP CON ID"
                required >
            </v-select>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Het ID van de ICP CON waar de iRose2 op aangesloten is. In vrijwel alle gevallen zal dit icpcon-1 zijn (dit is de ICP CON met ip 192.168.1.3).</span>
            </v-tooltip>
          </span>
          <v-col cols="12" md="2">
            <v-text-field
              id="iRoseIcpconRelaisNummer"
              v-model="icpconRelaisNummer"
              :rules="icpconRelaisNummerRules"
              label="ICP CON Relais Nummer"
              required filled
            ></v-text-field>
          </v-col>
          <span class="mt-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>Duidt aan welk relais van de ICP CON gebruikt wordt om de iRose te herstarten. Dit is te vinden in het kastenpakket.</span>
            </v-tooltip>
          </span>
        </v-row>
        <v-row >
          <v-btn id="iRoseAddBtn" :disabled="!valid" class="mt-5 ml-3" depressed color="primary" @click="addIRose">Toevoegen</v-btn>
        </v-row>
        <v-row class="mx-0 mt-6" >
          <span id="iRoseTooManyItemsWarning" v-if="tooManyItems" class="warning--text">Maximum number of iRoses reached</span>
        </v-row>
    </v-form>        
  </div>
</template>

<script>
/**
  *  The iRoseConfigForm component consists of a form with fields to configure an iRose.
  *  component: iRoseConfig - summary table of iRoses in the current iwks configuration
  */
import iRoseConfig from '@/components/iRoseConfig.vue'

  export default {
    name: 'iRoseConfigForm',

    /**
     *  valid: boolean to store whether the form is valid (i.e. has no errors)
     *  hardwareId: string containing the iRose ID
     *  host: string containing the host address
     *  hostOptions: the possible host options
     *  hostRules: rules used to determine whether the field is a valid host
     *  port: string containing the port number
     *  portOptions: array with possible default values for the iWKS port that the iRose connects to
     *  portRules: rules used to determine whether the field is a valid port number
     *  sshPort: string containing the ssh port number
     *  sshUsername: string containing the ssh username
     *  sshPassword: string containing the sshPassword
     *  requiredRules: rules used to determine whether a required field is filled out
     *  verschuiving: integer containing the verschuiving
     *  verschuivingRules: rules used to determine whether the field is a valid verschuiving value
     *  lusparen: integer containing the number of lusparen
     *  lusparenRules: rules used to determine whether the field is a valid number of lusparen value
     *  iRoses: array of iRoses currently added to the configuration
     *  tooManyItems: boolean used to show an error message when the maximum number of iRoses in the list is reached
     */
    data: () => ({
        valid: true,
        hardwareId: 'iRose1',
        host: '192.168.1.41',
        hostOptions: ['192.168.1.41', '192.168.1.42', '192.168.1.43', '192.168.1.44'],
        hostRules: [
            v => !!v || 'Host is required',
            v => /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(v) || 'Host must be a valid ip address',
        ],
        port: '12321',
        portOptions: ['12321', '12322', '12323', '12324'],
        portRules: [
            v => !!v || 'Port is required',
            v => Number.isInteger(Number(v)) || "The value must be an integer number",
            v => Number(v) > 0 && Number(v) < 65536|| "The value must be a valid port number",
        ],
        sshPort: '22',
        sshUsername: 'root',
        sshPassword: 'admin',
        requiredRules: [
            v => !!v || 'Field is required',
        ],
        verschuiving: 0,
        verschuivingRules: [
            v => v!=="" || 'Field is required',
            v => Number.isInteger(Number(v)) || "The value must be an integer number",
            v => Number(v) >= 0 && Number(v) <= 16|| "Valid values are 0-16",
        ],
        lusparen: 1,
        lusparenRules: [
            v => !!v || 'Field is required',
            v => Number.isInteger(Number(v)) || "The value must be an integer number",
            v => Number(v) > 0 && Number(v) < 5|| "Valid values are 1-4",
        ],
        icpconId: 'icpcon-1',
        icpconIdOptions: ['icpcon-1', 'icpcon-2'],
        icpconRelaisNummer: 1,
        icpconRelaisNummerRules: [
            v => v!=="" || 'Field is required',
            v => Number.isInteger(Number(v)) || "The value must be an integer number",
            v => Number(v) >= 0 && Number(v) <= 7|| "Valid values are 0-7",
        ],
        iRoses: [],
        tooManyItems: false
    }),

    methods: {
      /**
       *  Button click addIRose
       *  Adds an iRose with the config of values filled out in the form tot he array of iRoses
       *  Resets the values of the form to the default values 
       */
      addIRose() {
        if (!this.$refs.form.validate()) return;
        const iRose = {
          hardwareId: this.hardwareId,
          host: this.host,
          port: this.port,
          ssh: {
            port: this.sshPort,
            username: this.sshUsername,
            password: this.sshPassword
          },
          verschuiving: this.verschuiving,
          lusparen: this.lusparen,
          icpconId: this.icpconId,
          icpconRelaisNummer: this.icpconRelaisNummer,
        };
        this.iRoses.push(iRose);
        this.resetIRoseInputFields();
      },

      /**
       *  Resets values of the form to default values
       */
      resetIRoseInputFields() {
        this.hardwareId = this.newIRoseId();
        this.host = '192.168.1.41';
        this.port = '12321';
        this.sshPort = 22;
        this.sshUsername = 'root';
        this.sshPassword = 'admin';
        this.verschuiving = 0;
        this.lusparen = 1;
        this.icpconId = "icpcon-1"
        this.icpconRelaisNummer = 1;
      },
      /**
       *  Method used to generate a new unique id as default iRose id in the config field
       */
      newIRoseId() {
        var nr = 0;
        var id = "iRose1";
        while (this.iRoses.some(el => el.hardwareId === id)) {
          nr++;
          id = 'iRose' + nr.toString();
        }
        return id;
      },

      /**
       *  The list of iRoses is modified from within this component (when adding iRoses to the list) and from the iRoseConfig component (when deleting iRoses from the list).
       *  Therefore, checking the size of the current number of iRoses in the list should be done in this method , which is a vue event triggered from its child component iRoseConfig.
       *  vue-event: iRosesListUpdated -  when the list of iRoses is updated in the iRoseConfig the new updated list is sent to the Config vue. 
       */
      onListUpdated() {        
        this.$emit('iRosesListUpdated', this.iRoses);
        if (this.iRoses.length >= 8) {
          this.valid = false;
          this.tooManyItems = true;
        } else {
          this.valid = true;
          this.tooManyItems = false;
        }
      },
      /**
       *  Method used to determine the rules for the id field in the form.
       *  Other rules are determined as arrays in data objects, but there you cannot access other data objects and we need to access the iRoses array
       */
      idRules( value ) {
        if (value.length == 0 || value === "" ) {
          return "Id is required";
        }

        if (value.length > 20 ) {
          return "The value must be less than 20 characters"
        }

        if (this.iRoses.some(el => el.hardwareId === value)) {
          return "Identifier must be unique";
        }

        return true;
      },
       /**
       *  Method used to update the iWKS port field value depending on the selected iRose host value.
       */
      updateIWksPort(host){
        switch (host) {
          case '192.168.1.41':
            this.port = '12321';
            break;
          case '192.168.1.42':
            this.port = '12322';
            break;
          case '192.168.1.43':
            this.port = '12323';
            break;
          case '192.168.1.44':
            this.port = '12324';
            break;
        }
      }
    },
    components: {
      iRoseConfig
    }
  }
</script>