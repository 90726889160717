<template>
  <div>
    <h2>LAN Switch configuratie</h2>

    <LanSwitchConfig
      :lanswitches="lanswitches"
      :show-hardware-id="true"
      :show-delete-button="true"
      v-on:lanswitchesListUpdated="onListUpdated"
    />

    <v-form ref="form" class="mt-6">
      <v-row>
        <v-col cols="12" md="2">
          <v-text-field
            id="lanswitchId"
            v-model="lanswitchId"
            required
            filled
            disabled
          ></v-text-field>
        </v-col>
        <span class="mt-3">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="primary" class="mb-4" v-bind="attrs" v-on="on"
                >mdi-information-outline</v-icon
              >
            </template>
            <span
              >De LAN Switch configuratie kan niet aangepast worden. Er kunnen alleen
              maximaal 2 LAN Switches verwijderd of toegevoegd worden aan de
              configuratie.</span
            >
          </v-tooltip>
        </span>
      </v-row>
      <v-row>
        <v-btn
          id="lanswitchAddBtn"
          :disabled="!valid"
          class="mt-5 ml-3"
          depressed
          color="primary"
          @click="addLanswitch"
          >Toevoegen</v-btn
        >
      </v-row>
      <v-row class="mx-0 mt-6">
        <span
          id="lanswitchesTooManyItemsWarning"
          v-if="tooManyItems"
          class="warning--text"
          >Maximum number of LAN Switches reached</span
        >
      </v-row>
    </v-form>
  </div>
</template>

<script>
/**
 *  The LanSwitchConfigForm component consists of a form with fields to configure the LAN Switches.
 *  component: LanSwitchConfig - summary table of lanswitches in the current iwks configuration
 */
import LanSwitchConfig from "@/components/LanSwitchConfig.vue";

export default {
  name: "LanSwitchConfigForm",

  data: () => ({
    valid: false,
    lanswitchId: "LAN Switch 2",
    lanswitches: [{ lanswitchId: "LAN Switch 1" }, { lanswitchId: "LAN Switch 2" }],
    tooManyItems: true,
  }),

  methods: {
    /**
     *  Button click addLanswitch
     *  Adds an LAN Switch to the array
     */
    addLanswitch() {
      if (!this.$refs.form.validate()) return;
      const lanswitch = {
        lanswitchId: this.lanswitchId,
      };
      this.lanswitches.push(lanswitch);
      this.resetLanSwitchInputFields();
    },
    /**
     *  Resets values of the form to default values
     */
    resetLanSwitchInputFields() {
      this.lanswitchId = this.newLanSwitchId();
    },
    /**
     *  Method used to generate a new unique id as default lanswitch id in the config field
     */
    newLanSwitchId() {
      if (this.lanswitches.length < 1 ) {
        return "LAN Switch 1";
      }
      return "LAN Switch 2";
    },

    /**
     *  The list of lanswitches is modified from within this component (when adding lanswitches to the list) and from the LanSwitchConfig component (when deleting lanswitches from the list).
     *  Therefore, checking the size of the current number of lanswitches in the list should be done in this method , which is a vue event triggered from its child component LanSwitchConfig.
     *  vue-event: lanswitchesListUpdated -  when the list of lanswitches is updated in the LanSwitchConfig the new updated list is sent to the Config vue.
     */
    onListUpdated() {
      this.$emit("lanswitchesListUpdated", this.lanswitches);
      if (this.lanswitches.length >= 2) {
        this.valid = false;
        this.tooManyItems = true;
      } else {
        this.valid = true;
        this.tooManyItems = false;
      }
      this.resetLanSwitchInputFields();
    },
  },
  components: {
    LanSwitchConfig,
  },
};
</script>
