import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        testSessionId: '' 
    },
    mutations: {
        setTestSessionId(state, testSessionId) {
            state.testSessionId = testSessionId;
        }
    },
    actions: {},
    modules: {},
    getters: {
        getCurrentTestSessionId: state => state.testSessionId
    }
});