<template><v-app>
  <div class="pa-6">
    <v-card style="display: block; margin-left: auto; margin-right: auto" max-width="50%" v-if="!queryCompleted">
      <v-card-title>Pagina wordt geladen...</v-card-title>
      <v-card-text>
        Mocht dit langer dan een aantal seconden duren, probeer dan de pagina te verversen of de testsessie opnieuw te selecteren.
      </v-card-text>
    </v-card>
    <div id="pdfContent" ref="pdfContent" v-else>
      <v-container class="word-table" style="margin-top: 20px">
        <!-- HEADER -->
        <v-row>
          <v-col cols="12" md="4">
            <img src="../assets/rijkswaterstaat_logo.png" style="width: 200px" />
          </v-col>
          <!-- liever een align-self="center" dan margin-top, alleen dat werkt niet met de conversie naar PDF  -->
          <v-col cols="12" md="6" style="margin-top: 30px; margin-left: 50px">
            <h5 class="ml-2" style="display: inline">iFAT:</h5>
            <span style="font-size: small"> {{ iwksConfiguration.general.project }} </span>
          </v-col>
        </v-row>

        <!-- Configuratiegegevens -->
        <v-row class="border-top-right-left">
          <v-col cols="12" md="12">
            <h5 class="ml-2">Configuratiegegevens</h5>
          </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4">
            <span>Type kast</span>
          </v-col>
          <v-col cols="12" md="8" class="border-right-left">
            <span class="pa-2 ma-2"> {{ iwksConfiguration.general.stationType }} </span>
          </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4">
            <span>Kastnaam</span>
          </v-col>
          <v-col cols="12" md="8" class="border-right-left">
            <span class="pa-2 ma-2"> {{ iwksConfiguration.general.alias }} </span>
          </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4">
            <span>MAC-adres</span>
          </v-col>
          <v-col cols="12" md="8" class="border-right-left">
            <span class="pa-2 ma-2"> {{ macAddress }} </span>
          </v-col>
        </v-row>

        <!-- Productie IP-Gegevens -->
        <v-row class="border-top-right-left">
          <v-col cols="12" md="12">
            <h5 class="ml-2">Productie IP-Gegevens</h5>
          </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4">
            <span>IP-adres</span>
          </v-col>
          <v-col cols="12" md="8" class="border-right-left">
            <span class="pa-2 ma-2">
              {{ iwksConfiguration.general.prodIpAddress }}
            </span>
          </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4">
            <span>Subnet Mask</span>
          </v-col>
          <v-col cols="12" md="8" class="border-right-left">
            <span class="pa-2 ma-2"> {{ iwksConfiguration.general.prodSubnet }} </span>
          </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4">
            <span>Gateway</span>
          </v-col>
          <v-col cols="12" md="8" class="border-right-left">
            <span class="pa-2 ma-2"> {{ iwksConfiguration.general.prodGateway }} </span>
          </v-col>
        </v-row>

        <!-- Beheer IP-Gegevens -->
        <v-row class="border-top-right-left">
          <v-col cols="12" md="12">
            <h5 class="ml-2">Beheer IP-Gegevens</h5>
          </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4">
            <span>IP-adres</span>
          </v-col>
          <v-col cols="12" md="8" class="border-right-left">
            <span class="pa-2 ma-2">
              {{ iwksConfiguration.general.opsIpAddress }}
            </span>
          </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4">
            <span>Subnet Mask</span>
          </v-col>
          <v-col cols="12" md="8" class="border-right-left">
            <span class="pa-2 ma-2"> {{ iwksConfiguration.general.opsSubnet }} </span>
          </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4">
            <span>Gateway</span>
          </v-col>
          <v-col cols="12" md="8" class="border-right-left">
            <span class="pa-2 ma-2"> {{ iwksConfiguration.general.opsGateway }} </span>
          </v-col>
        </v-row>

        <!-- iFat Resultaat -->
        <v-row class="border-top-right-left">
          <v-col cols="12" md="4">
            <h5 class="ml-2" style="display: inline">iFAT Resultaat</h5>
          </v-col>
          <v-col cols="12" md="8">

            <span id="testresultaat">{{ tests | parseResult }}</span>
          </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4">
            <span>Aantal Geslaagde Testcases</span>
          </v-col>
          <v-col cols="12" md="8" class="border-right-left">
            <span class="pa-2 ma-2"> {{ nrSuccessfulTests }} </span>
          </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4">
            <span>Aantal Gefaalde Testcases</span>
          </v-col>
          <v-col cols="12" md="8" class="border-right-left">
            <span class="pa-2 ma-2"> {{ nrFailedTests }} </span>
          </v-col>
        </v-row>

        <!-- Locatie, Datum en Opmerkingen -->
        <v-row class="border-top-right-left">
          <v-col cols="12" md="12">
            <h5 class="ml-2">Locatie, Datum en Opmerkingen</h5>
          </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4">
            <span>Locatie HW-leverancier</span>
          </v-col>
          <v-col cols="12" md="8" class="border-right-left">
            <span class="pa-2 ma-2"> {{ iwksConfiguration.general.location }} </span>
          </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4">
            <span>Datum iFAT</span>
          </v-col>
          <v-col cols="12" md="8" class="border-right-left">
            <span class="pa-2 ma-2">
              {{ this.$options.filters.parseDate(createdAt) }}
            </span>
          </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4">
            <span>Opmerkingen</span>
          </v-col>
          <v-col cols="12" md="8" class="opmerkingen-col border-right-left">
            <span class="pa-2 ma-2"> </span>
          </v-col>
        </v-row>

        <!-- Aanwezigen -->
        <v-row class="border-top-right-left">
          <v-col cols="12" md="4">
            <h5 class="ml-2">Aanwezigen</h5>
          </v-col>
          <v-col cols="12" md="8">
            <h5 class="ml-2">Handtekening</h5>
          </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4"> </v-col>
          <v-col cols="12" md="8" class="aanwezigen-col border-right-left"> </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4"> </v-col>
          <v-col cols="12" md="8" class="aanwezigen-col border-right-left"> </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4"> </v-col>
          <v-col cols="12" md="8" class="aanwezigen-col border-right-left"> </v-col>
        </v-row>
        <v-row class="border-top-left">
          <v-col cols="12" md="4"> </v-col>
          <v-col cols="12" md="8" class="aanwezigen-col border-right-left"> </v-col>
        </v-row>
        <v-row class="border-last">
          <v-col cols="12" md="4"> </v-col>
          <v-col cols="12" md="8" class="aanwezigen-col border-right-left"> </v-col>
        </v-row>
      </v-container>

      <br /><br />
      <h2>Configuratie</h2>
      <v-divider></v-divider>
      <br><br>
      <div v-if="iwksConfiguration.icpcons.length !== 0">
        <h3>ICP CON configuratie</h3>
        <IcpConConfig :icpcons=iwksConfiguration.icpcons :show-hardware-id="true" :show-delete-button="false" /> <br>
      </div>

      <div v-if="iwksConfiguration.iRose2s.length !== 0">
        <h3>iRose2 configuratie</h3>
        <iRoseConfig :i-roses=iwksConfiguration.iRose2s :show-hardware-id="true" :show-delete-button="false" /> <br>
      </div>

      <div v-if="iwksConfiguration.sld4s.length !== 0">
        <h3>SLD4 configuratie</h3>
        <Sld4Config :sld4s=iwksConfiguration.sld4s :show-hardware-id="true" :show-delete-button="false" /> <br>
      </div>

      <div v-if="iwksConfiguration.ld16s.length !== 0">
        <h3>LD16 configuratie</h3>
        <Ld16Config :ld16s=iwksConfiguration.ld16s :show-hardware-id="true" :show-delete-button="false" /> <br>
      </div>

      <div v-if="iwksConfiguration.falconRadars.length !== 0">
        <h3>Falcon Radar configuratie</h3>
        <FalconRadarConfig :falconRadars=iwksConfiguration.falconRadars :show-hardware-id="true" :show-delete-button="false" /> <br>
      </div>

      <div v-if="iwksConfiguration.koperKoppelvlak">
        <h3>Yunex C3 koper koppelrack configuratie</h3>
        <KoperKoppelvlakConfig :koperKoppelvlakken=createKoperKoppelvlakArray(iwksConfiguration.koperKoppelvlak) :show-hardware-id="false" :show-delete-button="false" /> <br>
      </div>

      <div v-if="iwksConfiguration.profibussen.length !== 0">
        <h3>Profibus configuratie</h3>
        <profibusConfig :profibuses=iwksConfiguration.profibussen :show-hardware-id="true" :show-delete-button="false" />
      </div>
      <br>
      <h1>Resultaten</h1>
      <v-data-table
          id="testTable"
          :headers="getTestHeaders()"
          :items="getTestsInformation(tests)"
          class="elevation-1 mb-7"
          v-bind:style="'white-space:break-spaces'"
          item-key="id"
          :hide-default-footer="true"
          :items-per-page="-1"
      ></v-data-table>

    </div>
    <v-btn id="downloadPdfBtn" unit-test-id="button" class="mt-5" depressed color="primary" @click="downloadTestresultaten(iwksConfiguration.general.alias)">Download PDF</v-btn>
  </div>
</v-app></template>

<script>
import jsPDF from 'jspdf'
import gql from 'graphql-tag'
import IcpConConfig from '@/components/IcpConConfig.vue'
import iRoseConfig from '@/components/iRoseConfig.vue'
import Sld4Config from '@/components/Sld4Config.vue'
import Ld16Config from '@/components/Ld16Config.vue'
import FalconRadarConfig from '@/components/FalconRadarConfig.vue'
import KoperKoppelvlakConfig from '@/components/KoperKoppelvlakConfig.vue'
import ProfibusConfig from '@/components/ProfibusConfig.vue'
import util from '@/components/util'

export default {
  name: 'testresultaten',
  components: {
    IcpConConfig,
    iRoseConfig,
    Sld4Config,
    Ld16Config,
    FalconRadarConfig,
    KoperKoppelvlakConfig,
    ProfibusConfig
  },
  /**
   *  iwksConfiguration: IwksConfiguration of TestSession
   *  macAddress: MAC address (String) of iwks corresponding to the TestSession
   *  createdAt: DateTime of moment TestSession was created
   *  tests: List of Tests in TestSession
   *  queryCompleted: Whether the data filling in the above fields was successfully queried
   */
  data () {
    return {
      iwksConfiguration: null,
      macAddress: null,
      createdAt: null,
      tests: null,
      queryCompleted: false,
      nrSuccessfulTests: 0,
      nrFailedTests: 0
    }
  },
  beforeCreate() {
    this.$apollo.query({
      query: gql`query iwksConfigurationQuery($id: UUID!) {
                testSessionById(testSessionId: $id) {
                    createdAt,
                    iwksConfiguration {
                      general {
                        project, alias, location, stationType, opsIpAddress, opsGateway, opsSubnet, prodIpAddress, prodGateway, prodSubnet},
                      icpcons {
                        icpconId }
                      iRose2s {
                        hardwareId, host, port, icpconId, icpconRelaisNummer, ssh {
                          port, username, password } },
                      sld4s {
                        hardwareId, host, port, icpconId, icpconRelaisNummer, lusparen },
                      ld16s {
                        hardwareId, host, port, icpconId, icpconRelaisNummer, lusparen },
                      falconRadars {
                        hardwareId, host },
                      koperKoppelvlak {
                        hostPLC, hostDSO, hostDSZ },
                      profibussen {
                        profibusId, host, icpconId, icpconRelaisNummer, mussen {
                          pbSlaveAddress, rotatiePaneel } } },
                    iwks { macAddress },
                    tests { hardwareId, testType, executions { createdAt, lastUpdatedAt, state, lastFatClientFeedback } }
               }
            }`,
      variables: {
          id: this.$store.getters.getCurrentTestSessionId
      },
      fetchPolicy: "no-cache"
    }).then(data => {
        this.iwksConfiguration = data.data.testSessionById.iwksConfiguration;
        this.macAddress = data.data.testSessionById.iwks.macAddress;
        this.createdAt = data.data.testSessionById.createdAt;

        this.tests = data.data.testSessionById.tests;

        util.sortTests(this.tests);

        this.queryCompleted = true;
        console.info("Successfully queried testSession information");
    }).catch(error => console.error("an error occurred : " + error));
  },
  filters: {
    parseDate: function (zonedDateTime) {
      return util.parseDate(zonedDateTime);
    },
    parseResult: function (tests) {
      return util.determineTestResult(tests);
    }
  },
  methods: {
    /**
     *  Download de testresultaten als PDF
     */
    downloadTestresultaten(alias) {
      let doc = new jsPDF({
        unit: "px"
      });
      doc.html(this.$refs.pdfContent, {
        callback: function(doc) {
          // Save the PDF
          doc.save(alias + '-testrapport.pdf');
        },
        margin: [10, 10, 10, 10],
        format: 'a4',
        width: 425,
        windowWidth: 850
      });
    },

    /**
     * @param tests Array met alle tests
     * @returns {any[]} met de nodige informatie over de tests
     */
    getTestsInformation(tests) {
      let testInformation = util.getBaseTestsInformation(tests);

      let success = testInformation.filter(item => item.state === "Geslaagd").length;
      let fail = testInformation.filter(item => item.state === "Gefaald").length;

      this.nrSuccessfulTests = success;
      this.nrFailedTests = fail;

      return testInformation.map(item => ({
        ...item,
        createdAt: item.state !== "Niet Uitgevoerd" && item.createdAt ? util.parseDateTime(item.createdAt) : null,
        lastFatClientFeedback: item.state !== "Niet Uitgevoerd" ? item.lastFatClientFeedback : null
      }));
    },

    /**
     * @returns [] met headers voor de lijst met testen
     */
    getTestHeaders() {
      return [
        // Individueel sortable: false, zodat sort-by wel gebruikt kan worden in de table
        {text: 'Testgevallen', value: 'testType', width: '50px', sortable: false},
        {text: 'Omschrijving', value: 'testDescription', width: '400px', sortable: false},
        {text: 'Hardware ID', value: 'hardwareId', width: '100px', sortable: false},
        {text: 'Tijd Gestart', value: 'createdAt', width: '100px', sortable: false},
        {text: 'Resultaat', value: 'state', width: '150px', sortable: false},
        {text: 'Details', value: 'lastFatClientFeedback', sortable: false},
      ]
    },

    /**
     *  vue-prop-data: the component KoperKoppelvlakConfig expects an array. Convert the object to an array of 1 object.
     */
    createKoperKoppelvlakArray(koperKoppelvlak) {
        let koperKoppelvlakken = [];
        koperKoppelvlakken.push(koperKoppelvlak);
        return koperKoppelvlakken;
    }
  },
}
</script>