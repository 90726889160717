var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Algemene configuratie")]),_c('v-form',{ref:"form",staticClass:"mt-6",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"id":"project","rules":_vm.textFieldRules,"counter":50,"label":"Projectnaam/Blok/Fase","filled":""},on:{"change":_vm.onConfigurationChanged},model:{value:(_vm.generalConfiguration.project),callback:function ($$v) {_vm.$set(_vm.generalConfiguration, "project", $$v)},expression:"generalConfiguration.project"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"id":"alias","rules":_vm.textFieldRules,"counter":50,"label":"Kast-naam","filled":""},on:{"change":_vm.onConfigurationChanged},model:{value:(_vm.generalConfiguration.alias),callback:function ($$v) {_vm.$set(_vm.generalConfiguration, "alias", $$v)},expression:"generalConfiguration.alias"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Vul hier een alias voor de kast in om de testsessie later makkelijker terug te vinden.")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"id":"locatie","rules":_vm.textFieldRules,"counter":50,"label":"Locatie HW-leverancier","filled":""},on:{"change":_vm.onConfigurationChanged},model:{value:(_vm.generalConfiguration.location),callback:function ($$v) {_vm.$set(_vm.generalConfiguration, "location", $$v)},expression:"generalConfiguration.location"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Vul hier de locatie van de HW-leverancier in.")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{attrs:{"id":"stationType","filled":"","items":_vm.stationTypeOptions,"rules":_vm.stationTypeRules,"return-object":false,"label":"Type kast","required":""},on:{"change":_vm.onConfigurationChanged},model:{value:(_vm.generalConfiguration.stationType),callback:function ($$v) {_vm.$set(_vm.generalConfiguration, "stationType", $$v)},expression:"generalConfiguration.stationType"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"id":"prodIpAddress","rules":_vm.textFieldRules,"counter":50,"label":"Productie ip-adres","filled":""},on:{"change":_vm.onConfigurationChanged},model:{value:(_vm.generalConfiguration.prodIpAddress),callback:function ($$v) {_vm.$set(_vm.generalConfiguration, "prodIpAddress", $$v)},expression:"generalConfiguration.prodIpAddress"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Vul hier het productie ip-adres in.")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"id":"prodSubnet","rules":_vm.textFieldRules,"counter":50,"label":"Productie subnet","filled":""},on:{"change":_vm.onConfigurationChanged},model:{value:(_vm.generalConfiguration.prodSubnet),callback:function ($$v) {_vm.$set(_vm.generalConfiguration, "prodSubnet", $$v)},expression:"generalConfiguration.prodSubnet"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Vul hier het productie subnet in.")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"id":"prodGateway","rules":_vm.textFieldRules,"counter":50,"label":"Productie gateway","filled":""},on:{"change":_vm.onConfigurationChanged},model:{value:(_vm.generalConfiguration.prodGateway),callback:function ($$v) {_vm.$set(_vm.generalConfiguration, "prodGateway", $$v)},expression:"generalConfiguration.prodGateway"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Vul hier de productie gateway in.")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"id":"opsIpAddress","rules":_vm.textFieldRules,"counter":50,"label":"Beheer ip-adres","filled":""},on:{"change":_vm.onConfigurationChanged},model:{value:(_vm.generalConfiguration.opsIpAddress),callback:function ($$v) {_vm.$set(_vm.generalConfiguration, "opsIpAddress", $$v)},expression:"generalConfiguration.opsIpAddress"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Vul hier het beheer ip-adres in.")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"id":"opsSubnet","rules":_vm.textFieldRules,"counter":50,"label":"Beheer subnet","filled":""},on:{"change":_vm.onConfigurationChanged},model:{value:(_vm.generalConfiguration.opsSubnet),callback:function ($$v) {_vm.$set(_vm.generalConfiguration, "opsSubnet", $$v)},expression:"generalConfiguration.opsSubnet"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Vul hier het beheer subnet in.")])])],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"id":"opsGateway","rules":_vm.textFieldRules,"counter":50,"label":"Beheer gateway","filled":""},on:{"change":_vm.onConfigurationChanged},model:{value:(_vm.generalConfiguration.opsGateway),callback:function ($$v) {_vm.$set(_vm.generalConfiguration, "opsGateway", $$v)},expression:"generalConfiguration.opsGateway"}})],1),_c('span',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Vul hier de beheer gateway in.")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }