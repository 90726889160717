var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{attrs:{"id":"menuFromDate","close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"id":"menuFromDateTextfield","label":"Filter from date","prepend-icon":"mdi-calendar","clearable":"","readonly":""},on:{"click:clear":_vm.clearDateFrom},model:{value:(_vm.dateFromFormatted),callback:function ($$v) {_vm.dateFromFormatted=$$v},expression:"dateFromFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFromDate),callback:function ($$v) {_vm.menuFromDate=$$v},expression:"menuFromDate"}},[_c('v-date-picker',{attrs:{"id":"datepickerFromDate","no-title":"","pickerDate":_vm.pickerFromDate},on:{"input":function($event){return _vm.processPickedDate('from')},"update:pickerDate":function($event){_vm.pickerFromDate=$event},"update:picker-date":function($event){_vm.pickerFromDate=$event}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{attrs:{"id":"menuToDate","close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"id":"menuToDateTextfield","label":"Filter to date","prepend-icon":"mdi-calendar","clearable":"","readonly":"","rules":_vm.dateValidation},on:{"click:clear":_vm.clearDateTo},model:{value:(_vm.dateToFormatted),callback:function ($$v) {_vm.dateToFormatted=$$v},expression:"dateToFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuToDate),callback:function ($$v) {_vm.menuToDate=$$v},expression:"menuToDate"}},[_c('v-date-picker',{attrs:{"id":"datepicketToDate","no-title":"","pickerDate":_vm.pickerToDate},on:{"input":function($event){return _vm.processPickedDate('to')},"update:pickerDate":function($event){_vm.pickerToDate=$event},"update:picker-date":function($event){_vm.pickerToDate=$event}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 cursor",attrs:{"id":"testSessionList","headers":_vm.headers,"items":_vm.allTestSessions,"items-per-page":5,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"item-key":"id","single-select":true},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("parseDateTime")(item.createdAt)))])]}},{key:"item.controls",fn:function(props){return [_c('v-btn',{class:'delete-button',attrs:{"id":props.item.id,"plain":""},on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.deleteTestSessionPrompt(props.item)}]}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"item.tests",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.parseResult(item.tests)))])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }