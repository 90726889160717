var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"koperKoppelvlakTable","headers":_vm.headers,"items":_vm.koperKoppelvlakken,"item-key":"id","items-per-page":-1,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.hostPLC",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-start",attrs:{"id":_vm.createElementId('host-PLC', item)}},[_vm._v(" "+_vm._s(item.hostPLC)+" ")])]}},{key:"item.hostDSO",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-start",attrs:{"id":_vm.createElementId('host-DSO', item)}},[_vm._v(" "+_vm._s(item.hostDSO)+" ")])]}},{key:"item.hostDSZ",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-start",attrs:{"id":_vm.createElementId('host-DSZ', item)}},[_vm._v(" "+_vm._s(item.hostDSZ)+" ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"id":_vm.createElementId('delete'),"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }